import React, { FC } from 'react';
import useAutoScroll from 'hooks/useAutoScroll';

const App: FC = ({ children }) => {
  useAutoScroll();

  return <>{children}</>;
};

export default App;
