import { useContext } from 'react';

import { PortalName, PortalType } from 'settings';

import { PortalContext, PortalParamsType } from './PortalContext';

export const usePortalContext = () => {
  const portalContext = useContext(PortalContext) as PortalParamsType;

  return {
    ...portalContext,
    portalType: portalContext.portalType as PortalType,
    portalName: portalContext.portalName as PortalName,
  };
};
