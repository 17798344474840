import React, { ReactElement } from 'react';
import WholesaleDisclaimer from 'components/Footer/WholesaleDisclaimer';
import RetailDisclaimer from 'components/Footer/RetailDisclaimer';
import {
  productTableHeaderTitles,
  retailProductTableHeaderTitles,
} from 'components/PrequalificationResults/PassedProductsTable/PassedProductsTable';
import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';
import {
  retailStrategies,
  Strategy,
  wholesailStrategies,
} from 'components/Filters/ProductsFilter/StrategyOrders.constants';

interface PortalSettings {
  footerDisclaimer: ReactElement;
  showLenderInformationOnSubmissionFormPdf: boolean;
  showFooterOnSubmissionFormPdf: boolean;
  getLpcRateRangeInitialValue: (lpcRates: number[]) => number[] | null;
  getLpcPriceRangeInitialValue: (lpcPrices: number[]) => number[] | null;
  getBpcPointsRangeInitialValue: (bpcPoints: number[]) => number[] | null;
  productHeaderContainerClassName: string;
  getPassedProductsTableHeaderTitles: () => PassedProductTableHeaderTitle[];
  productsFilterRelevantStrategies: Strategy[];
  getPrequalificationLenderPaidCompensation: (value: string) => number | null;
  validateLenderPaidCompensation: boolean;
  showLenderInformationOnPrequalificationPage: boolean;
}

export enum PortalType {
  Wholesale = 'Wholesale',
  Retail = 'Retail',
}

export enum PortalName {
  Wholesale = 'StandardWholesale',
  Retail = 'StandardRetail',
}

export const settings: Record<PortalType, PortalSettings> = {
  [PortalType.Wholesale]: {
    footerDisclaimer: <WholesaleDisclaimer />,
    showLenderInformationOnSubmissionFormPdf: true,
    showFooterOnSubmissionFormPdf: false,
    getLpcRateRangeInitialValue: (lpcRates: number[]) => [Math.min(...lpcRates), Math.max(...lpcRates)],
    getLpcPriceRangeInitialValue: (lpcPrices: number[]) => [Math.min(...lpcPrices), Math.max(...lpcPrices)],
    getBpcPointsRangeInitialValue: () => null,
    productHeaderContainerClassName: 'container',
    getPassedProductsTableHeaderTitles: () => productTableHeaderTitles,
    productsFilterRelevantStrategies: wholesailStrategies,
    getPrequalificationLenderPaidCompensation: (value: string) => Number(value),
    validateLenderPaidCompensation: true,
    showLenderInformationOnPrequalificationPage: true,
  },
  [PortalType.Retail]: {
    footerDisclaimer: <RetailDisclaimer />,
    showLenderInformationOnSubmissionFormPdf: false,
    showFooterOnSubmissionFormPdf: true,
    getLpcRateRangeInitialValue: () => null,
    getLpcPriceRangeInitialValue: () => null,
    getBpcPointsRangeInitialValue: (bpcPoints: number[]) => [Math.min(...bpcPoints), Math.max(...bpcPoints)],
    productHeaderContainerClassName: 'retailPortalContainer',
    getPassedProductsTableHeaderTitles: () => retailProductTableHeaderTitles,
    productsFilterRelevantStrategies: retailStrategies,
    getPrequalificationLenderPaidCompensation: () => null,
    validateLenderPaidCompensation: false,
    showLenderInformationOnPrequalificationPage: false,
  },
};
