import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { searchAccounts } from 'thunks';

import { getSignInProcessState } from 'handlers/selectors';
import {  setPhone } from 'handlers/signInProcessSlice';

import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useLoadingState } from 'hooks/useLoadingState';

import { ReactComponent as LeftArrow } from 'images/arrow-left.svg';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import { ButtonType } from 'components/Button/Button';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';

import InputError from 'components/InputError';

import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';
import { PageErrors } from 'enums/PageErrors';

import RequestError from 'errors/RequestError';

import { createFormikHelpers } from 'utils/formHelpers';

import {
  IPhoneNumberFormValues,
  PhoneNumberFormFields,
  phoneNumberFormValidationSchema,
} from 'validationSchemas/phoneNumberStepValidation';

import styles from './SignInProcessStep.module.scss';

const CreateAccountPhoneStep = ({ changeStep }: ISignInStepProps) => {

  const { phone } = useAppSelector(getSignInProcessState);
  const [phoneError, setPhoneError] = useState<string>('');
  
  const [checkDisabled, setCheckDisabled ] = useState<boolean>(false);
  const dispatch = useDispatch();

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [loadingState, setLoadingState] = useLoadingState();

  const skipStep = () => {
    dispatch(setPhone({ phone: null }));
    return changeStep(SignInProcessSteps.CreatePasswordStep);
  }
  
  const handleSubmit = async (values: IPhoneNumberFormValues) => {
    try {
      setLoadingState('loading');
      dispatch(setPhone({ phone: values[PhoneNumberFormFields.Phone] }));
      const { isAccountExists } = await dispatchWithUnwrap(searchAccounts(values[PhoneNumberFormFields.Phone]));

      if (isAccountExists) {
        setLoadingState('error');
        setCheckDisabled(true);
        setPhoneError(PageErrors.AccountWithPhoneExists);
        return;

      }
     /* const { isPhoneMatch } = await dispatchWithUnwrap(checkPhoneMatches({phoneNumber: values[PhoneNumberFormFields.Phone], intermediaryId}));
     
      if(!isPhoneMatch){
        setLoadingState('error');
        setCheckDisabled(true);
        setPhoneError(PageErrors.PhoneMatch);
        return;
      } */
     
      setLoadingState('success');
      return changeStep(SignInProcessSteps.CreatePasswordStep);
    } catch (err) {
      const error = err as RequestError;

      if (error.message) {
        setPhoneError(PageErrors.TechnicalIssue);
      }
      setLoadingState('error');
    }
  };

  const isLoading = loadingState === 'loading';

  const subTitleElement = <span><p>{PageSubtitles.CreateAccountPhone}</p><p>{PageSubtitles.CreateAccountSkip}</p></span>;
  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.CreateAccount} subTitle={subTitleElement} />
      <Formik
        initialValues={{ phone: phone || ''}}
        validationSchema={phoneNumberFormValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors, submitForm, isValid, setFieldValue, setTouched }) => {
          const handleContinue = async () => {
            await submitForm();
          };

          const { handleInputChange, handleTouched, getInputErrorMessage,handleResetExternalError } = createFormikHelpers<
            PhoneNumberFormFields,
            IPhoneNumberFormValues
          >(setFieldValue, setTouched, touched, errors, { phone: phoneError },
            () => setPhoneError(''));
          return (
            <section className={styles.contentWrapper}>
              <label className={getInputErrorMessage(PhoneNumberFormFields.Phone) && styles.inputError}>
                Cell Phone
                <PhoneNumberInput
                  value={phone || ''}
                  onChange={(e) =>{
                    setCheckDisabled(false);
                    handleInputChange(PhoneNumberFormFields.Phone)(e);
                  }
                  }
                  onBlur={handleTouched(PhoneNumberFormFields.Phone)}
                  onFocus={handleResetExternalError(PhoneNumberFormFields.Phone)
                  }
                  errormessage={getInputErrorMessage(PhoneNumberFormFields.Phone)}
                  required
                />
                {getInputErrorMessage(PhoneNumberFormFields.Phone) && (
                  <InputError errorMessage={getInputErrorMessage(PhoneNumberFormFields.Phone)!} />
                )}
              </label>
              <div className={styles.buttonsContainer}>
                <Button type={ButtonType.Secondary} className={styles.backBtn} onClick={() => changeStep(SignInProcessSteps.CreateAccountEmailStep)}>
                  <LeftArrow />
                </Button>
                <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid || checkDisabled}>
                  Next
                </Button>
              </div>
              <div className={styles.skipButtonContainer}>
                <Button type={ButtonType.Secondary} onClick={skipStep} isLoading={isLoading}>
                  Skip
                </Button>
              </div>
            </section>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAccountPhoneStep;
