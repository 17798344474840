import React, { FC, useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getLPCForCompany } from 'thunks';
import { useAppSelector } from 'hooks/reduxHooks';
import { getSignInProcessState } from 'handlers/selectors';
import InputSelect from 'components/InputSelect';
import { PageErrors } from 'enums/PageErrors';
import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';

import styles from './CompanyAccounts.module.scss';

interface CompanyAccountsProps {
  onChange: (inputName: PrequalificationInputName, value: string) => void;
  formikValues: FormikValues;
  getErrorMessage: (inputName: PrequalificationInputName) => string | undefined;
}

const CompanyAccounts: FC<CompanyAccountsProps> = ({ 
  onChange,
  formikValues,
  getErrorMessage,
 }) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { companiesData } = useAppSelector(getSignInProcessState);
  const [companyError, setCompanyError] = useState<string>('');
  
  useEffect(() => {
    if(!formikValues[PrequalificationInputName.CompanyName])
     setCompanyError('');
}, [formikValues]);

  const handleChange = async (value: string) => {
    setCompanyError('');
    onChange(PrequalificationInputName.CompanyName, value);
    const selectedId = companiesData?.find(item => item.companyName === value)?.companyId;
    try {
      if (selectedId) {
        const { lenderPaidCompensation } = await dispatchWithUnwrap(getLPCForCompany(selectedId));
        onChange(PrequalificationInputName.LenderPaidCompensation, lenderPaidCompensation);
      }
    } catch (err) {
      // TODO update error message
      onChange(PrequalificationInputName.LenderPaidCompensation, '');
      setCompanyError(PageErrors.NoCompanyInfoShort);
    }

  };
  return (
    <div className={styles.container}>
      <h3 className={styles.sectionTitle}>Account</h3>
      <div className={styles.companyAccountsContainer}>
        <div className={styles.inputs}>
          <InputSelect
            label={PrequalificationInputLabel.CompanyName}
            onChange={(value) => handleChange(value)}
            value={formikValues[PrequalificationInputName.CompanyName]}
            options={companiesData?.map(company=> company.companyName) || []}
            name={PrequalificationInputName.CompanyName}
            placeholder="Select"
            className={styles.formInput}
            errorMessage={getErrorMessage(PrequalificationInputName.CompanyName) || companyError}
          />
        </div>
      </div>
    </div>
  );
};
export default CompanyAccounts;
