import { createAsyncThunk } from '@reduxjs/toolkit';
import SignUpProcessApi from 'api/SignUpProcessApi';
import { SignUpProcessActionTypes } from 'enums/SignUpProcessActionTypes';

export const createSearchAccountsThunk = (api: SignUpProcessApi) =>
  createAsyncThunk(SignUpProcessActionTypes.SearchAccounts, async (phoneNumber: string) => {
    const response = await api.searchAccounts(phoneNumber);
    return response;
  });


export const createSearchAccountsByEmailThunk = (api: SignUpProcessApi) =>
    createAsyncThunk(SignUpProcessActionTypes.SearchAccountsByEmail, async (email: string) => {
      const response = await api.searchAccountsByEmail(email);
      return response;
    });
