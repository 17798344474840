import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { sendUpdatePhoneCode, verifyUpdatePhoneCode } from 'thunks';
import { getChangePhoneProcessState } from 'handlers/selectors';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useTimer from 'hooks/useTimer';
import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import Input from 'components/Input';
import InputError from 'components/InputError';
import { ButtonType } from 'components/Button/Button';

import { ReactComponent as LeftArrow } from 'images/arrow-left.svg';

import RequestError from 'errors/RequestError';

import { createFormikHelpers } from 'utils/formHelpers';

import { PageTitles } from 'enums/PageTitles';
import { PageErrors } from 'enums/PageErrors';

import {
  IVerificationCodeFormValues,
  VerificationCodeFormFields,
  verificationCodeValidationSchema,
} from 'validationSchemas/verificationCodeStepValidation';
import { IChangePhonePageProps } from 'pages/ChangePhonePage/ChangePhonePage';

import { ChangePhoneProcessSteps } from 'pages/ChangePhonePage/constants';

import styles from './ChangePhoneProcessStep.module.scss'

const CODE_LENGTH = 6;
const RESEND_RATE = 60;

const ConfirmCodeStep = ({ nextStep }: IChangePhonePageProps) => {
  const [loadingState, setLoadingState] = useLoadingState();
  const [firstSent, setFirstSent] = useState(false);
  const { newPhone, passwordValidationToken } = useAppSelector(getChangePhoneProcessState);

  const { isRunning, restart, seconds } = useTimer(RESEND_RATE, {
    autoStart: true,
  });

  const [verificationCodeError, setVerificationCodeError] = useState<string>('');
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState<boolean>(false);
  const [verificationDisabled, setVerificationDisabled] = useState<boolean>(false);

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleBack = () => nextStep(ChangePhoneProcessSteps.NewPhoneStep);

  const descriptionElement = <span>We've sent you a phone code <b>{newPhone}</b>. Please enter the phone code to change phone number.</span>;
 
  const handleSendVerificationCode = async () => {
    try {
      setLoadingState('loading');
      setIsVerificationCodeSent(false);
      setVerificationCodeError('');
    
      await dispatchWithUnwrap(sendUpdatePhoneCode({ phone: newPhone, passwordValidationToken }));

      setFirstSent(true);
      restart(RESEND_RATE);
      setIsVerificationCodeSent(true);
      setLoadingState('success');
    } catch (err) {
      setFirstSent(true);
      const error = err as RequestError;
      setLoadingState('error');
      // TODO add logic with different error codes such as 400, 429
      if (error.responseStatus === 409) {
          setVerificationCodeError(PageErrors.ChangePhoneCodeIssue);
          setVerificationDisabled(true);
          setIsVerificationCodeSent(false);
          return;
      }

      if (error.responseStatus === 429) {
        const secondsLeft = +error.message.replace(/\D/gim, '');

        restart(secondsLeft);

        setIsVerificationCodeSent(true);
        setVerificationCodeError((err as Error).message);
        return;
      }

      setIsVerificationCodeSent(false);
      setVerificationCodeError((err as Error).message);
    }
  };

  const handleSubmit = async (values: IVerificationCodeFormValues) => {
    try {
      setLoadingState('loading');
      await dispatchWithUnwrap(verifyUpdatePhoneCode({ phone: newPhone, code: values[VerificationCodeFormFields.Code] }));
      setLoadingState('success');
      nextStep(ChangePhoneProcessSteps.CompleteStep);
    } catch (err) {
      const error = err as RequestError;
      if (error.responseStatus === 401) {
        setVerificationCodeError(PageErrors.VerifyEmailCode);
      }
      if (error.responseStatus === 400) {
        setVerificationCodeError(PageErrors.VerifyEmailCode);
      }
      setLoadingState('error');
    }
  };

  useEffect(() => {
    handleSendVerificationCode();
  }, []);

  const isLoading = loadingState === 'loading';

  const renderCodeStatus = (clearInput: () => void) => {
    if (isVerificationCodeSent) {
      if (isRunning) {
        return <span className={styles.resendText}>Resend Code in {seconds} s.</span>;
      }

      return (
        <div className={styles.linkContainer} onClick={() => {
          handleSendVerificationCode();
          clearInput();
        }}>
          <span className={styles.title}>Resend Code</span>
        </div>
      );
    }
  };

  return (
    (firstSent && !isLoading) ?
      (<>
        <FormTitle title={PageTitles.ChangePhone} subTitle={descriptionElement} />
        <Formik
          initialValues={{ code: '' }}
          validationSchema={verificationCodeValidationSchema}
          validateOnBlur
          validateOnChange
          validateOnMount
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ touched, errors, submitForm, isValid, setTouched, setFieldValue, values }) => {
            const clearInput = () => {
              setFieldValue('code', '');
              setTouched({ code: false }, false);
            };
            const handleContinue = async () => {
              await submitForm();
              clearInput();
            };

            const { handleInputChange, handleTouched, getInputErrorMessage, handleResetExternalError } =
              createFormikHelpers<VerificationCodeFormFields, IVerificationCodeFormValues>(
                setFieldValue,
                setTouched,
                touched,
                errors,
                { code: verificationCodeError },
                () => setVerificationCodeError(''),
              );
            return (
              <section className={styles.contentWrapper}>
                <div>
                  <label className={getInputErrorMessage(VerificationCodeFormFields.Code) && styles.inputError}>
                   Phone Authentication Code
                    <Input
                      value={values[VerificationCodeFormFields.Code]}
                      onChange={(e) => handleInputChange(VerificationCodeFormFields.Code)(e.target.value)}
                      onBlur={handleTouched(VerificationCodeFormFields.Code)}
                      onFocus={handleResetExternalError(VerificationCodeFormFields.Code)}
                      maxLength={CODE_LENGTH}
                      required
                      disabled={verificationDisabled}
                    />
                    {getInputErrorMessage(VerificationCodeFormFields.Code) && (
                      <InputError errorMessage={getInputErrorMessage(VerificationCodeFormFields.Code)!} />
                    )}
                  </label>
                  {renderCodeStatus(clearInput)}
                </div>
                <div className={styles.buttonsContainer}>
                  <Button type={ButtonType.Secondary} className={styles.backBtn} onClick={handleBack}>
                      <LeftArrow />
                  </Button>
                  <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid || verificationDisabled}>
                    Change Phone Number
                  </Button>
                </div>
              </section>
            );
          }}
        </Formik>
      </>) :
      (<div className={styles.skeleton}>
        <h1><Skeleton /></h1>
        <div><Skeleton count={5} /></div>
        <h1><Skeleton /></h1>
      </div>
      )

  );
};

export default ConfirmCodeStep;
