import React, { memo, CSSProperties, ReactNode } from 'react';
import { Menu, PopoverOrigin, MenuProps } from '@mui/material';
import styles from './Popup.module.scss';

export type ContextualPopupOrigin = PopoverOrigin;
export type ContextualPopupAnchorEl = MenuProps['anchorEl'];
export type ContextualPopupAnchorPosition = MenuProps['anchorPosition'];
export type ContextualPopupClasses = MenuProps['classes'];
export type ContextualPopupPopoverClasses = MenuProps['PopoverClasses'];

export interface IContextualPopupProps {
  open: boolean;
  className?: string;
  anchorOrigin?: ContextualPopupOrigin;
  transformOrigin?: ContextualPopupOrigin;
  anchorEl?: ContextualPopupAnchorEl;
  anchorPosition?: ContextualPopupAnchorPosition;
  classes?: ContextualPopupClasses;
  popoverClasses?: ContextualPopupPopoverClasses;
  style?: CSSProperties;
  children?: ReactNode;
  disablePortal?: boolean;
  onClose?: () => void;
}

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'right' };
const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'right' };
const MenuListProps = { disablePadding: true };

const ContextualPopUp = memo(({
  anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
  transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
  popoverClasses,
  ...restProps
}: IContextualPopupProps) => {
  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PopoverClasses={popoverClasses}
      MenuListProps={MenuListProps}
      className={styles.contextualPopup}
      {...restProps}
    />
  );
});

export default ContextualPopUp;
