import { createAsyncThunk } from '@reduxjs/toolkit';
import { IntermediaryApi } from 'api/IntermediaryApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetIntermediaryDataThunk = (api: IntermediaryApi) =>
  createAsyncThunk('intermediaryActionType/getIntermediaryData', async (id: string, thunkApi) => {
    try {
      return await api.getIntermediaryData(id);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });

export const checkPhoneMatchesThunk = (api: IntermediaryApi) =>
  createAsyncThunk('intermediaryActionType/checkPhoneMatches',
    async ({ phoneNumber, intermediaryId }: { phoneNumber: string; intermediaryId: string | null }) => {
      const response = await api.checkPhoneMatches(phoneNumber, intermediaryId);
      return response;
    });

export const createGetIntermediaryFullDataThunk = (api: IntermediaryApi) =>
  createAsyncThunk('intermediaryActionType/getIntermediaryFullData', async (id: string) => {
    const response = await api.getIntermediaryFullData(id);
    return response;
  });

  export const createGetLPCForCompanyThunk = (api: IntermediaryApi) =>
    createAsyncThunk('intermediaryActionType/getLPCForCompany', async (companyId: string ) => {
      const response = await api.getLPCForCompany(companyId);
      return response;
    });
