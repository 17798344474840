import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import SignInRightBar from 'components/SignInRightBar';
import Header from 'components/Header';
import FormTitle from 'components/FormTitle';
import LogOut from 'components/LogOut';
import Button from 'components/Button';
import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';
import { ButtonType } from 'components/Button/Button';
import { usePortalContext } from 'providers/usePortalContext';
import { PortalType } from 'settings';
import styles from './SorryPage.module.scss';


const SorryPage = () => {
  const history = useHistory();
  const { portalType, setPortalType } = usePortalContext();
  const [initialPortalType, setInitialPortalType] = useState(portalType);

  useEffect(() => {
    setInitialPortalType(portalType);
    setPortalType(PortalType.Wholesale);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Header disposedInBlock />
          <div className={styles.form}>
            <div className={styles.formWrapper}>
              <FormTitle title={PageTitles.Sorry} subTitle={PageSubtitles.Sorry} />
              <div className={styles.contentWrapper}>
                <Button
                  type={ButtonType.Primary}
                  onClick={() => {
                    setPortalType(initialPortalType);
                    history.push('/');
                  }}
                >
                  Return to Inputs page
                </Button>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <LogOut />
            </div>
          </div>
        </div>
      </div>
      <SignInRightBar />
      <div className={styles.footer}>
        <p>{new Date().getFullYear()} © Logan Finance Corporation. All rights reserved.</p>
      </div>
    </div>
  );
};

export default SorryPage;
