import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ChangePhoneProcessSteps } from 'pages/ChangePhonePage/constants';
import { createPasswordValidationToken, sendUpdatePhoneCode, verifyUpdatePhoneCode } from 'thunks';


export interface IChangePhoneProcessState {
  newPhone: string | null;
  changePhoneStep: ChangePhoneProcessSteps;
  passwordValidationToken: string | null;
  isLoading: boolean;
}

const initialState: IChangePhoneProcessState = {
  newPhone: null,
  changePhoneStep: ChangePhoneProcessSteps.NewPhoneStep,
  passwordValidationToken: null,
  isLoading: false
};

const ChangePhoneProcessSlice = createSlice({
  name: 'changePhone',
  initialState,
  reducers: {
    setNewPhone: (state, action: PayloadAction<{ newPhone: string | null }>) => {
      state.newPhone = action.payload.newPhone;
    },
    setChangePhoneStep:  (state, action: PayloadAction<ChangePhoneProcessSteps>) => {
      state.changePhoneStep = action.payload;
    },
    resetChangePhoneProcess: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPasswordValidationToken.fulfilled, (state, { payload: { passwordValidationToken, ...rest } }) => {
       state.passwordValidationToken = passwordValidationToken;
      })
      .addCase(sendUpdatePhoneCode.fulfilled, (state)  => {
       state.isLoading = false;
      })
      .addCase(sendUpdatePhoneCode.rejected, (state)  => {
       state.isLoading = false;
      })
      .addCase(sendUpdatePhoneCode.pending, (state)  => {
        state.isLoading = true;
      })
      .addCase(verifyUpdatePhoneCode.fulfilled, (state)  => {
        state.isLoading = false;
       })
       .addCase(verifyUpdatePhoneCode.rejected, (state)  => {
        state.isLoading = false;
       })
       .addCase(verifyUpdatePhoneCode.pending, (state)  => {
         state.isLoading = true;
       })
  },
});

export const { setNewPhone, setChangePhoneStep, resetChangePhoneProcess } = ChangePhoneProcessSlice.actions;
export default ChangePhoneProcessSlice.reducer;
