import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkPricingAvailabilityData } from 'thunks';

export interface PricingAvailabilityResultState {
  pricingAvailable: boolean,
  intermediaryPriceAdjustment: number | null;
}

const initialState: PricingAvailabilityResultState = {
  pricingAvailable: true,
  intermediaryPriceAdjustment: null,
};

const checkPricingAvailabilityResultReducer = createSlice({
  name: 'pricingAvailabilityResult',
  initialState,
  reducers: {
    setPricingAvailabilityData: (state: PricingAvailabilityResultState, {payload}:  PayloadAction<PricingAvailabilityResultState>)=>{
     state.pricingAvailable = payload.pricingAvailable;
     state.intermediaryPriceAdjustment = payload.intermediaryPriceAdjustment;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkPricingAvailabilityData.fulfilled, (state, { payload }) => {
      state.pricingAvailable = payload?.pricingAvailable;
      state.intermediaryPriceAdjustment = payload?.intermediaryPriceAdjustment;
    });
  },
});

export const { setPricingAvailabilityData } = checkPricingAvailabilityResultReducer.actions;

export default checkPricingAvailabilityResultReducer.reducer;
