import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentStep } from 'handlers/signInProcessSlice';

import SignInRightBar from 'components/SignInRightBar';
import Header from 'components/Header';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';

import { usePortalContext } from 'providers/usePortalContext';
import CreatePasswordStep from 'components/SignInLeftBar/steps/CreatePasswordStep';
import { PortalType } from 'settings';
import styles from './ResetPasswordPage.module.scss';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const { portalType, setPortalType } = usePortalContext();
  const [, setInitialPortalType] = useState(portalType);
  const changeStep = (step: SignInProcessSteps) => dispatch(setCurrentStep(step));

  useEffect(() => {
    setInitialPortalType(portalType);
    setPortalType(PortalType.Wholesale);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Header disposedInBlock />
          <div className={styles.form}>
            <div className={styles.formWrapper}>  
              <CreatePasswordStep changeStep={changeStep} resetPassword={true as boolean} />
            </div>
          </div>
        </div>
      </div>
      <SignInRightBar />
      <div className={styles.footer}>
        <p>{new Date().getFullYear()} © Logan Finance Corporation. All rights reserved.</p>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
