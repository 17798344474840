import  RequestError  from 'errors/RequestError';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

const rejectWithValueHelper = (
  error: Error,
  thunkApi: BaseThunkAPI<unknown, unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown, unknown, unknown>,
) => {
  if (error instanceof RequestError) {
    return thunkApi.rejectWithValue({
      responseStatus: error?.responseStatus,
      message: error?.message,
    });
  }

  throw error;
};

export default rejectWithValueHelper;
