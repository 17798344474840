import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import TokenUtils from 'utils/TokenUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createVerifySignInCodeThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.VerifyCode, async (code: string, thunkApi) => {
    try {
      const { accessToken, refreshToken } = await api.verifyCode(code);

      TokenUtils.setTokens({ accessToken, refreshToken });
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
