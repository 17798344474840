import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createSendUpdatePhoneCodeThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.SendUpdatePhoneVerificationCode,
     async ({ phone, passwordValidationToken }: {phone: string | null, passwordValidationToken: string | null }, thunkApi) => {
    try {
      await api.sendUpdatePhoneCode(phone, passwordValidationToken);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
