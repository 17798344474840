import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createResetPasswordEmailThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.ResetPassword, async (email: string, thunkApi) => {
    try {
      await api.resetPassword(email);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
