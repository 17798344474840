import React, { FC } from 'react';
import clsx from 'clsx';
import CreditProfile from 'components/PrequalificationForm/CreditProfile';
import LoanCriteria from 'components/PrequalificationForm/LoanCriteria';
import PropertyInformation from 'components/PrequalificationForm/PropertyInformation';
import Footer from 'components/Footer';
import CompanyAccounts from 'components/CompanyAccounts';
import LenderInformation from 'components/PrequalificationForm/LenderInformation';
import { ProductType } from 'enums/ProductType';
import { Application } from 'handlers/prequalificationForm';
import { settings } from 'settings';
import { Theme } from 'components/PrequalificationResultPDF/PrequalificationResultPDF';
import { useAppSelector } from 'hooks/reduxHooks';
import { getSignInProcessState } from 'handlers/selectors';
import { usePortalContext } from 'providers/usePortalContext';

import styles from './SubmissionForm.module.scss';

interface AlternativeDocumentationProps {
  values: Application;
  productType: ProductType;
  colors: Theme | null;
}

const SubmissionForm: FC<AlternativeDocumentationProps> = ({ values, productType, colors }) => {
  // No functionality is required for printing only
  const { portalType } = usePortalContext();
  const { isAccountExecutive } = useAppSelector(getSignInProcessState);
  const showDebtToIncome =
    productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation;

  const showDscr = productType === ProductType.DebtServiceCoverageRatio;

  const showIncomeDocumentType =
    productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation;

  return (
    <div className={clsx(styles.container, isAccountExecutive && styles.twoPages)}>
      <div className={styles.pageInfo}>
        <p>Loan Terms:</p>
      </div>
      <h1
        className={styles.header}
        style={colors ? { color: colors.primary100 } : {}} // global style variables don't work inside print window
      >
        {productType}
      </h1>
      {isAccountExecutive &&
        <div className={styles.loanCriteria}>
          <CompanyAccounts
            onChange={() => { }}
            formikValues={values}
            getErrorMessage={() => ''}
          />
        </div>
      }
      <div className={styles.loanCriteria}>
        <LoanCriteria
          onChange={() => { }}
          showDebtToIncome={showDebtToIncome}
          showDscr={showDscr}
          showIncomeDocumentType={showIncomeDocumentType}
          validatePercentInput={() => false}
          formikValues={values}
          productType={productType}
          getErrorMessage={() => ''}
        />
      </div>
      {isAccountExecutive && 
        (
        <>
          <div className={styles.pageBreak} />
          <div className={styles.pageInfo}>
            <p>Loan Terms:</p>
          </div>
        </>
       )
      }
      <div className={styles.bottomBar}>
        <div className={styles.creditProfile}>
          <CreditProfile onChange={() => { }} formikValues={values} getErrorMessage={() => ''} />
        </div>
        <div className={styles.propertyInformation}>
          <PropertyInformation
            onChange={() => { }}
            productType={productType}
            onSearchInputBlur={() => { }}
            formikValues={values}
            getErrorMessage={() => ''}
            onBlur={() => { }}
          />
        </div>
      </div>
      {settings[portalType].showLenderInformationOnSubmissionFormPdf && (
        <div className={styles.bottomBar}>
          <LenderInformation formikValues={values} />
        </div>
      )}
      {settings[portalType].showFooterOnSubmissionFormPdf && <Footer />}
    </div>
  );
};

export default SubmissionForm;
