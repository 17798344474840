import React, { FC } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { ReactComponent as LogoutIcon } from 'images/logout-empty.svg';
import { ReactComponent as ChangePhoneIcon } from 'images/change-phone.svg';
import ActionPopUp from 'components/ActionPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/Popup/ContextualPopUp';
import { removeSessionStorageItem } from 'utils/removeSessionStorageItem';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { useDispatch } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { resetPreQualificationFormData } from 'handlers/prequalificationForm';
import { logout } from 'thunks';

import styles from './AccountMenu.module.scss';

interface IAccountMenuProps {
  onClose: () => void;
  anchorElement: Element | null;
  isMobile?: boolean;
}

const AccountMenu: FC<IAccountMenuProps> = ({
  anchorElement,
  onClose,
  isMobile
}) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const history = useHistory();
  const handleChangePhoneNumner = () => {
    
    onClose();
    return history.push('/change-phone');
  };

  const handleLogout = async () => {
    onClose();
    removeSessionStorageItem(SessionStorageKeyName.PreQualificationForm);
    dispatch(resetPreQualificationFormData());
    await dispatchWithUnwrap(logout());
  };

  return (
    <ContextualPopUp
      anchorOrigin={{ horizontal: isMobile ? 'right' : 'left', vertical: 'top' }}
      transformOrigin={{
        vertical: isMobile ? 'top' : 'center',
        horizontal: isMobile ? 'right' : 'left',
      }}
      anchorEl={anchorElement}
      open
      onClose={onClose}
      classes={{ paper: styles.popupContainer }}
      disablePortal
    >
      <ActionPopUp title='My Account' className={clsx(styles.popup, isMobile && styles.mobileView)} onClose={onClose} isMobile={isMobile}>
        <ActionPopUpItem
          onClick={handleChangePhoneNumner}
        >
          Change Phone Number
          <ChangePhoneIcon />
        </ActionPopUpItem>
        <ActionPopUpItem
          onClick={handleLogout}
          danger
        >
          Log Out
          <LogoutIcon />
        </ActionPopUpItem>
      </ActionPopUp>
    </ContextualPopUp>
  );
};

export default AccountMenu;
