import { createAsyncThunk } from '@reduxjs/toolkit';
import SignInProcessApi from 'api/SignInProcessApi';
import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetCurrentAccountThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.GetCurrentAccount, async (_, thunkApi) => {
    try {
      return await api.getCurrentAccount();
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
