import React, { FC, useRef, useState } from 'react';
import Header from 'components/Header';
import ProductTypeSwitcher from 'components/ProductTypeSwitcher';
import LogOut from 'components/LogOut';
import MyAccount from 'components/MyAccount';
import { ProductType } from 'enums/ProductType';
import AccountMenu from 'components/AccountMenu';

import styles from './HomeLeftBar.module.scss';

const switcherOptions = [
  {
    title: ProductType.AlternativeDocumentation,
    subtitle: 'Programs utilizing alternative documentation such as bank statements or assets.',
  },
  {
    title: ProductType.FullDocumentation,
    subtitle: 'Programs utilizing traditional income documentation.',
  },
  {
    title: ProductType.DebtServiceCoverageRatio,
    subtitle: 'Programs utilizing the cash flow of the subject property.',
  },
];

interface HomeLeftBarProps {
  onChange: (value: ProductType) => void;
  currentProduct: string;
}

const HomeLeftBar: FC<HomeLeftBarProps> = ({ onChange, currentProduct }) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [menuState, setMenuState] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  
  const renderMenu = () => {    
    return (
      <AccountMenu
        onClose={() => setMenuState(false)}
        anchorElement={isMobile ? headerRef?.current: footerRef?.current}
        isMobile={isMobile}
      />
    );
  
};
  return (<div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.topBar}>
        <div className={styles.menu}>
        <div className={styles.headerBar}>
          <Header disposedInBlock />
          <div className={styles.logoutInHeader}>
            <MyAccount forMobile ref={headerRef} clickEvent={()=>{
              setMenuState(true);
              setIsMobile(true);
            }}/>
          </div>
        </div>

        <div className={styles.contentContainer}>
          <h4 className={styles.switcherTitle}>Product Type</h4>
          <div>
            {switcherOptions.map((option) => (
              <ProductTypeSwitcher
                type="radio"
                name="Product Type"
                title={option.title}
                subtitle={option.subtitle}
                onChange={() => onChange(option.title)}
                checked={currentProduct === option.title}
                key={option.title}
                className={styles.radioButton}
              />
            ))}
          </div>
        </div>
        </div>
        {menuState && renderMenu()}
        <div className={styles.buttonContainer}>
          <MyAccount clickEvent={()=>{
            setMenuState(true);
            setIsMobile(false);
          }
          } ref={footerRef}/>
        </div>
      </div>
    </div>
  </div>)
};

export default HomeLeftBar;
