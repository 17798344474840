import React, { useEffect, useState } from 'react';

import { resetPasswordEmail } from 'thunks';

import { getSignInProcessState } from 'handlers/selectors';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useTimer from 'hooks/useTimer';
import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';

import { ButtonType } from 'components/Button/Button';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';

import RequestError from 'errors/RequestError';

import { PageTitles } from 'enums/PageTitles';

import styles from './SignInProcessStep.module.scss';

const RESEND_RATE = 60;

const ResetPasswordInfoStep = ({ changeStep }: ISignInStepProps) => {
  const [loadingState] = useLoadingState();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isRunning, restart, seconds } = useTimer(RESEND_RATE, {
    autoStart: true,
  });

  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState<boolean>(false);

  const { email } = useAppSelector(getSignInProcessState);


  const resetDescriptionElement = <span> We've sent you an email to <b>{email}</b>. If an account with such email exists, you will get the email with a link to reset your password. Please check your inbox and click the link to reset your password.</span>;

  const handleSendEmail = async () => {
    try {
      setIsVerificationCodeSent(false);
      await dispatchWithUnwrap(resetPasswordEmail(email));
      restart(RESEND_RATE);
      
      setIsVerificationCodeSent(true);
    } catch (err) {
      const error = err as RequestError;

      // TODO add logic with different error codes such as 400, 429
      if (error.responseStatus === 409) {
        setIsVerificationCodeSent(false);
        return;
      }

      if (error.responseStatus === 429) {
        const secondsLeft = +error.message.replace(/\D/gim, '');

        restart(secondsLeft);

        setIsVerificationCodeSent(true);

        return;
      }

      setIsVerificationCodeSent(false);

    }
  };

  useEffect(() => {
    handleSendEmail();
  }, []);

  const isLoading = loadingState === 'loading';

  const renderCodeStatus = () => {
    if (isVerificationCodeSent) {
      if (isRunning) {
        return <span className={styles.resendLinkOff}>Resend Email in {seconds} s.</span>;
      }

      return (
        <div className={styles.resendLinkOn} onClick={() => {
          handleSendEmail();
        }}>
          <span className={styles.title}>Resend Email</span>
        </div>
      );
    }
  };

  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.ResetPassword} subTitle={resetDescriptionElement} />
      <section className={styles.contentWrapper}>
        <div className={styles.buttonsContainer}>
          <Button type={ButtonType.Primary} onClick={() => changeStep(SignInProcessSteps.ResetPasswordStep)} isLoading={isLoading}>
            Back
          </Button>
        </div>
        <div className={styles.infoBlock}>
         <p className={styles.infoMessage}>Did not get the email?</p> 
          {renderCodeStatus()}
        </div>
       
      </section>
    </div>
  );
};

export default ResetPasswordInfoStep;
