import React, { useEffect, FC } from 'react';
import { Form, FormikErrors, FormikTouched } from 'formik';
import { Application, resetPreQualificationFormData, setPreQualificationFormData } from 'handlers/prequalificationForm';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useHistory } from 'react-router';
import { RootState } from 'handlers';
import Button from 'components/Button';
import { PreQualificationData } from 'api/PreQualificationApi';
import CompanyAccounts from 'components/CompanyAccounts';
import { ButtonType } from 'components/Button/Button';
import Separator from 'components/Separator';
import { OccupancyType } from 'enums/OccupancyType';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { PrequalificationVariable } from 'enums/PrequalificationVariable';
import { ProductType } from 'enums/ProductType';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { NumberFormatValues } from 'react-number-format';
import { checkPricingAvailabilityData, getPreQualificationData } from 'thunks';
import { calculateLoanAmount } from 'utils/calculateLoanAmount';
import { coerceFromPercent } from 'utils/coerceFromPercent';
import { coerceToIntegerNumber } from 'utils/coerceToIntegerNumber';
import { formatInputOption } from 'utils/formatInputOption';
import { removeSessionStorageItem } from 'utils/removeSessionStorageItem';
import { PortalType, settings } from 'settings';
import { useAppSelector } from 'hooks/reduxHooks';
import { getSignInProcessState } from 'handlers/selectors';
import CreditProfile from './CreditProfile';
import LenderInformation from './LenderInformation';
import LoanCriteria from './LoanCriteria';
import PropertyInformation from './PropertyInformation';
import styles from './PrequalificationForm.module.scss';

interface PrequalificationInputsProps {
  productType: ProductType;
  portalType: PortalType;
  values: Application;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: FormikErrors<Application>;
  isValid: boolean;
  touched: FormikTouched<Application>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  validateForm: (values?: any) => Promise<FormikErrors<Application>>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
}

const PrequalificationInputs: FC<PrequalificationInputsProps> = ({
  productType,
  portalType,
  values,
  setFieldValue,
  errors: formikErrors,
  isValid,
  touched,
  setFieldTouched,
  validateForm,
  handleBlur,
}) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { accountData, isAccountExecutive, companiesData} = useAppSelector(getSignInProcessState);

  const history = useHistory();

  const { isLoading, filters } = useSelector((state: RootState) => state.prequalificationResults);

  const showDebtToIncome =
    productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation;

  const showDscr = productType === ProductType.DebtServiceCoverageRatio;

  const showIncomeDocumentType =
    productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation;

  const validatePercentInput = (inputObj: NumberFormatValues, maxValue: number) => {
    const { floatValue, formattedValue } = inputObj;
    return formattedValue === '' || floatValue! <= maxValue;
  };

  useEffect(() => {
    validateForm(values);
  }, [productType]);

  useEffect(() => {
    setFieldValue(
      PrequalificationInputName.LoanAmount,
      calculateLoanAmount(
        values[PrequalificationInputName.PurchasePriceOrEstimatedValue],
        values[PrequalificationInputName.LoanToValue],
      ),
    );
  }, [values[PrequalificationInputName.PurchasePriceOrEstimatedValue], values[PrequalificationInputName.LoanToValue]]);

  useEffect(() => {
    if (productType === ProductType.DebtServiceCoverageRatio) {
      setFieldValue(PrequalificationInputName.Occupancy, OccupancyType.InvestmentProperty);
    }
  }, [productType]);

  const handleClearAll = async () => {
    removeSessionStorageItem(SessionStorageKeyName.PreQualificationForm);

    Object.keys(values).map((item) => {
      if (productType === ProductType.DebtServiceCoverageRatio && item === PrequalificationInputName.Occupancy) {
        return;
      }
      if (item === PrequalificationInputName.LenderPaidCompensation && !isAccountExecutive) {
        return;
      }

      return setFieldValue(item as PrequalificationInputName, '');
    });
  };

  const isFormChanged = Object.keys(values).some((item) => {
    if (productType === ProductType.DebtServiceCoverageRatio && item === PrequalificationInputName.Occupancy) {
      return;
    }

    return values[item as PrequalificationInputName]?.length;
  });

  const onFormikValueChange = (inputName: PrequalificationInputName, value: string) => {
    setFieldValue(inputName, value, true);
  };

  const getErrorMessage = (inputName: PrequalificationInputName) =>
    formikErrors[inputName] && touched[inputName] ? formikErrors[inputName] : undefined;

  const handleSubmit = async () => {
    if (!isValid) {
      Object.keys(values).map((key) => setFieldTouched(key));

      await validateForm(values);

      return;
    }

    const dataForGettingPrequalificationResult: PreQualificationData = {
      application: {
        [PrequalificationVariable.LoanPurpose]: values[PrequalificationInputName.LoanPurpose],
        [PrequalificationVariable.PurchasePriceOrEstimatedValue]: coerceToIntegerNumber(
          values[PrequalificationInputName.PurchasePriceOrEstimatedValue],
        )!,
        [PrequalificationVariable.LoanToValue]: coerceFromPercent(
          coerceToIntegerNumber(values[PrequalificationInputName.LoanToValue])!,
        )!,
        [PrequalificationVariable.LoanAmount]: coerceToIntegerNumber(values[PrequalificationInputName.LoanAmount])!,
        [PrequalificationVariable.Reserves]: values[PrequalificationInputName.Reserves],
        [PrequalificationVariable.DebtToIncome]: showDebtToIncome
          ? coerceFromPercent(Number(values[PrequalificationInputName.DebtToIncome]))
          : null,
        [PrequalificationVariable.DebtServiceCoverageRatio]: showDscr
          ? Number(values[PrequalificationInputName.DebtServiceCoverageRatio])
          : null,
        [PrequalificationVariable.IncomeDocumentType]: showIncomeDocumentType
          ? values[PrequalificationInputName.IncomeDocumentType]
          : null,
        [PrequalificationVariable.InterestOnly]: values[PrequalificationInputName.InterestOnly],
        [PrequalificationVariable.Citizenship]: values[PrequalificationInputName.Citizenship],
        [PrequalificationVariable.PrepaymentPenalty]: values[PrequalificationInputName.PrepaymentPenalty],
        [PrequalificationVariable.EscrowWaiver]: values[PrequalificationInputName.EscrowWaiver],
        [PrequalificationVariable.CreditScore]: coerceToIntegerNumber(values[PrequalificationInputName.CreditScore])!,
        [PrequalificationVariable.MortgagePaymentHistory]: formatInputOption(
          values[PrequalificationInputName.MortgagePaymentHistory],
        ),
        [PrequalificationVariable.CreditEvents]: formatInputOption(values[PrequalificationInputName.CreditEvents]),
        [PrequalificationVariable.PropertyType]: values[PrequalificationInputName.PropertyType],
        [PrequalificationVariable.Occupancy]: values[PrequalificationInputName.Occupancy],
        [PrequalificationVariable.Vesting]: values[PrequalificationInputName.Vesting],
        [PrequalificationVariable.State]: values[PrequalificationInputName.State],
        [PrequalificationVariable.County]: values[PrequalificationInputName.County],
        [PrequalificationVariable.SelfEmployed]: values[PrequalificationInputName.SelfEmployed],
        [PrequalificationVariable.LenderPaidCompensation]: settings[
          portalType
        ].getPrequalificationLenderPaidCompensation(values[PrequalificationInputName.LenderPaidCompensation]),
        [PrequalificationVariable.CompanyName]: values[PrequalificationInputName.CompanyName],
      },
      loanProductsFilter: filters.loanProductsFilter,
      lockTermFilter: filters.lockTermFilter,
      portalType,
    };
   
    const companyId = companiesData?.find(item => item.companyName === values[PrequalificationInputName.CompanyName])?.companyId || null;
    const pricingCheckResult = await dispatchWithUnwrap(checkPricingAvailabilityData({intermediaryId: accountData!.intermediaryId, companyId}));
   
    if(!pricingCheckResult){
      removeSessionStorageItem(SessionStorageKeyName.PreQualificationForm);
      dispatch(resetPreQualificationFormData());
      return history.push('/sign-in');
    }
  
    if(!pricingCheckResult.pricingAvailable){
      dispatch(
        setPreQualificationFormData({
          application: values as Application,
          coercedApplication: dataForGettingPrequalificationResult.application,
          productType,
          intermediaryPriceAdjustment: null
        }),
      );
     return history.push('/sorry');
    }
    
    await dispatchWithUnwrap(
      getPreQualificationData(
      {
        ...dataForGettingPrequalificationResult,
        intermediaryPriceAdjustment: pricingCheckResult.intermediaryPriceAdjustment as number
      }));

    dispatch(
      setPreQualificationFormData({
        application: values as Application,
        coercedApplication: dataForGettingPrequalificationResult.application,
        productType,
        intermediaryPriceAdjustment: pricingCheckResult.intermediaryPriceAdjustment
      }),
    );

    history.push('/results');
  };

  return (
    <Form>
      {isAccountExecutive  &&
        <div className={styles.companyAccounts}>
          <CompanyAccounts onChange={onFormikValueChange} formikValues={values} getErrorMessage={getErrorMessage}/>
        </div>
      }  
      <div className={styles.loanCriteria}>
        <LoanCriteria
          onChange={onFormikValueChange}
          showDebtToIncome={showDebtToIncome}
          showDscr={showDscr}
          showIncomeDocumentType={showIncomeDocumentType}
          validatePercentInput={validatePercentInput}
          formikValues={values}
          productType={productType}
          getErrorMessage={getErrorMessage}
        />
      </div>
      <div className={styles.bottomBar}>
        <div className={styles.creditProfile}>
          <CreditProfile onChange={onFormikValueChange} formikValues={values} getErrorMessage={getErrorMessage} />
        </div>
        <div className={styles.propertyInformation}>
          <PropertyInformation
            onChange={onFormikValueChange}
            productType={productType}
            onSearchInputBlur={handleBlur}
            formikValues={values}
            getErrorMessage={getErrorMessage}
            onBlur={handleBlur}
          />
        </div>
      </div>
      {settings[portalType].showLenderInformationOnPrequalificationPage && (
        <div className={styles.bottomBar}>
          <LenderInformation formikValues={values} />
        </div>
      )}
      <Separator />
      <div className={styles.buttons}>
        <div className={styles.clearAllContainer}>
          {isFormChanged && (
            <Button className={styles.clearAll} type={ButtonType.Secondary} onClick={handleClearAll}>
              Clear All
            </Button>
          )}
        </div>
        <div className={styles.submitContainer}>
          <Button className={styles.submit} disabledForRequest={!isValid} isLoading={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default PrequalificationInputs;
