export enum SignInProcessActionTypes {
  SendVerificationCode = 'signInProcess/sendVerificationCode',
  VerifyCode = 'signInProcess/verifyCode',
  VerifyPassword = 'signInProcess/verifyPassword',
  VerifySession = 'signInProcess/verifySession',
  SignOut = 'signInProcess/signOut',
  GetCurrentAccount = 'signInProcess/getCurrentAccount',
  ResetPassword = 'signInProcess/resetPassword',
  CreateNewPassword = 'signInProcess/createNewPassword',
  CreatePasswordValidationToken = 'signInProcess/createPasswordValidationToken',
  SendUpdatePhoneVerificationCode = 'signInProcess/sendUpdatePhoneVerificationCode',
  VerifyUpdatePhoneVerificationCode = 'signInProcess/verifyUpdatePhoneVerificationCode',
}
