import React, { useState } from 'react';
import { Formik } from 'formik';

import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';

import { getChangePhoneProcessState } from 'handlers/selectors';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import PasswordInput from 'components/PasswordInput';
import InputError from 'components/InputError';
import { ButtonType } from 'components/Button/Button';

import { ReactComponent as LeftArrow } from 'images/arrow-left.svg';

import { createFormikHelpers } from 'utils/formHelpers';

import RequestError from 'errors/RequestError';
import { createPasswordValidationToken } from 'thunks';
import { PageTitles } from 'enums/PageTitles';
import { PageErrors } from 'enums/PageErrors';
import { IChangePhonePageProps } from 'pages/ChangePhonePage/ChangePhonePage';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import {
  IChangePhonePasswordFields,
  IChangePhonePasswordFormValues,
  changePhoneStepValidationSchema,
} from 'validationSchemas/changePhoneStepValidation';

import { ChangePhoneProcessSteps } from 'pages/ChangePhonePage/constants';

import styles from './ChangePhoneProcessStep.module.scss'

const ConfirmPasswordStep = ({ nextStep }: IChangePhonePageProps) => {
  const [loadingState, setLoadingState] = useLoadingState();
  const [passwordError, setPasswordError] = useState<string>('');
  const { newPhone } = useAppSelector(getChangePhoneProcessState);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const descriptionElement = <span>You're about to change your phone number to <b>{newPhone}</b>. Please enter your password to confirm the change.</span>;

  const handleSubmit = async (values: IChangePhonePasswordFormValues) => {
    try {
      setLoadingState('loading');
      await dispatchWithUnwrap(
        createPasswordValidationToken(
          values[IChangePhonePasswordFields.Password],
        ),
      );
      nextStep(ChangePhoneProcessSteps.ConfirmCodeStep);
      setLoadingState('success');
    } catch (err) {
      const error = err as RequestError;
      setLoadingState('error');
      if (error.responseStatus === 400) {
        setPasswordError(PageErrors.PasswordChangePhone);
        return;
      }
      setPasswordError(PageErrors.PasswordChangePhone);

    }
  };

  const isLoading = loadingState === 'loading';

  return (
    <>
      <FormTitle title={PageTitles.ChangePhone} subTitle={descriptionElement} />
      <Formik
        initialValues={{ password: '' }}
        validationSchema={changePhoneStepValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors, submitForm, isValid, setTouched, setFieldValue, values }) => {

          const handleContinue = async () => {
            await submitForm();

          };

          const { handleInputChange, handleTouched, getInputErrorMessage, handleResetExternalError } =
            createFormikHelpers<IChangePhonePasswordFields, IChangePhonePasswordFormValues>(
              setFieldValue,
              setTouched,
              touched,
              errors,
              { password: passwordError },
              () => setPasswordError(''),
            );
          return (
            <section className={styles.contentWrapper}>
              <div>
                <label className={getInputErrorMessage(IChangePhonePasswordFields.Password) && styles.inputError}>
                  Password
                  <PasswordInput
                    name="password"
                    required
                    value={values[IChangePhonePasswordFields.Password]}
                    onChange={(e) => handleInputChange(IChangePhonePasswordFields.Password)(e.target.value)}
                    onBlur={handleTouched(IChangePhonePasswordFields.Password)}
                    onFocus={handleResetExternalError(IChangePhonePasswordFields.Password)}
                    disabled={isLoading}
                    autoComplete="password"
                  />
                  {getInputErrorMessage(IChangePhonePasswordFields.Password) && (
                    <InputError errorMessage={getInputErrorMessage(IChangePhonePasswordFields.Password)!} />
                  )}
                </label>
              </div>
              <div className={styles.buttonsContainer}>
                <Button type={ButtonType.Secondary} className={styles.backBtn} onClick={() => nextStep(ChangePhoneProcessSteps.NewPhoneStep)}>
                  <LeftArrow />
                </Button>
                <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid}>
                  Next
                </Button>
              </div>
            </section>
          );
        }}
      </Formik>
    </>
  );
};

export default ConfirmPasswordStep;
