import React from 'react';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import { ButtonType } from 'components/Button/Button';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';
import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';

import styles from './SignInProcessStep.module.scss';

const SignInOptionsStep = ({ changeStep }: ISignInStepProps) => {
  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.SignIn} subTitle={PageSubtitles.SignIn} />
      <section className={styles.contentWrapper}>
        <div>
          <Button type={ButtonType.Primary} onClick={() => changeStep(SignInProcessSteps.SignInStep)} >
            Sign In with Phone Code
          </Button>
          <Button type={ButtonType.Primary} onClick={() => changeStep(SignInProcessSteps.SignInByEmailStep)}>
            Sign In with Password
          </Button>
        </div>
        <div>
          Do not have an account?
          <Button
            type={ButtonType.Secondary}
            onClick={() => changeStep(SignInProcessSteps.CreateAccountEmailStep)}
          >
            Create Account
          </Button>
        </div>
      </section>
    </div>
  );
};

export default SignInOptionsStep;
