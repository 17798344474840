import React from 'react';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import { ButtonType } from 'components/Button/Button';
import { IChangePhonePageProps } from 'pages/ChangePhonePage/ChangePhonePage';
import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';
import styles from './ChangePhoneProcessStep.module.scss';

const CompleteStep = ({ nextStep, dismissStep }: IChangePhonePageProps) => {

  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.ChangePhone} subTitle={PageSubtitles.ChangePhoneSuccess} />
      <div className={styles.contentWrapper}>
        <Button
          type={ButtonType.Primary}
          onClick={() => dismissStep && dismissStep(true)}
        >
          Return to Inputs page
        </Button>
      </div>
    </div>
  );
};

export default CompleteStep;
