import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { searchAccounts } from 'thunks';
import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';

import { getChangePhoneProcessState } from 'handlers/selectors';
import RequestError from 'errors/RequestError';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import InputError from 'components/InputError';
import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { ButtonType } from 'components/Button/Button';
import { setNewPhone } from 'handlers/changePhoneProcessSlice';
import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';
import { PageErrors } from 'enums/PageErrors';
import { createFormikHelpers } from 'utils/formHelpers';
import { IChangePhonePageProps } from 'pages/ChangePhonePage/ChangePhonePage';
import {
  IPhoneNumberFormValues,
  PhoneNumberFormFields,
  phoneNumberFormValidationSchema,
} from 'validationSchemas/phoneNumberStepValidation';
import { ChangePhoneProcessSteps } from 'pages/ChangePhonePage/constants';

import styles from './ChangePhoneProcessStep.module.scss'

const NewPhoneNumberStep = ({ nextStep }: IChangePhonePageProps) => {
  const { newPhone } = useAppSelector(getChangePhoneProcessState);
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [phoneError, setPhoneError] = useState<string>('');
  
  const [checkDisabled, setCheckDisabled ] = useState<boolean>(false);
  
  const [loadingState, setLoadingState] = useLoadingState();

  const handleSubmit = async (values: IPhoneNumberFormValues) => {
    try {
      setLoadingState('loading');
      dispatch(setNewPhone({ newPhone: values[PhoneNumberFormFields.Phone] }));
      const { isAccountExists } = await dispatchWithUnwrap(searchAccounts(values[PhoneNumberFormFields.Phone]));
      
      if (isAccountExists) {
        setLoadingState('error');
        setCheckDisabled(true);
        setPhoneError(PageErrors.AccountWithPhoneExists);
        return;

      }
      setLoadingState('success');
      nextStep(ChangePhoneProcessSteps.ConfirmPasswordStep);
    } catch (err) {
      const error = err as RequestError;

      if (error.message) {
        setPhoneError(PageErrors.TechnicalIssue);
      }
      setLoadingState('error');
    }
  };

  const isLoading = loadingState === 'loading';

  return (
    <>
      <FormTitle title={PageTitles.ChangePhone} subTitle={PageSubtitles.ChangePhone} />
      <Formik
        initialValues={{ phone: newPhone || '' }}
        validationSchema={phoneNumberFormValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors, submitForm, isValid, setFieldValue, setTouched }) => {
          const handleContinue = async () => {
            await submitForm();
          };

          const { handleInputChange, handleTouched, getInputErrorMessage, handleResetExternalError } = createFormikHelpers<
            PhoneNumberFormFields,
            IPhoneNumberFormValues
          >(setFieldValue, setTouched, touched, errors, { phone: phoneError },
            () => setPhoneError(''));

          return (
            <section className={styles.contentWrapper}>
              <label className={getInputErrorMessage(PhoneNumberFormFields.Phone) && styles.inputError}>
                Cell Phone 
                <PhoneNumberInput
                  value={newPhone || ''}
                  onChange={(e) =>{
                    setCheckDisabled(false);
                    handleInputChange(PhoneNumberFormFields.Phone)(e);
                  }
                  }
                  onBlur={handleTouched(PhoneNumberFormFields.Phone)}
                  onFocus={handleResetExternalError(PhoneNumberFormFields.Phone)}
                  errormessage={getInputErrorMessage(PhoneNumberFormFields.Phone)}
                  required
                />
                {getInputErrorMessage(PhoneNumberFormFields.Phone) && (
                  <InputError errorMessage={getInputErrorMessage(PhoneNumberFormFields.Phone)!} />
                )}
              </label>
              <div className={styles.buttonsContainer}>
                <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid || checkDisabled}>
                  Next
                </Button>
                </div>
            </section>
          );
        }}
      </Formik>
    </>
  );
};

export default NewPhoneNumberStep;
