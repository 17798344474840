import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';

import { getSignInProcessState } from 'handlers/selectors';
import { setPhone } from 'handlers/signInProcessSlice';

import InputError from 'components/InputError';
import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { ButtonType } from 'components/Button/Button';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';

import { ReactComponent as LeftArrow } from 'images/arrow-left.svg';

import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';

import { createFormikHelpers } from 'utils/formHelpers';

import {
  IPhoneNumberFormValues,
  PhoneNumberFormFields,
  phoneNumberFormValidationSchema,
} from 'validationSchemas/phoneNumberStepValidation';

import styles from './SignInProcessStep.module.scss';

const PhoneNumberStep = ({ changeStep }: ISignInStepProps) => {
  const { phone } = useAppSelector(getSignInProcessState);
  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useLoadingState();

  const handleBack = () => changeStep(SignInProcessSteps.OptionsStep);

  const handleSubmit = async (values: IPhoneNumberFormValues) => {
    try {
      setLoadingState('loading');
      dispatch(setPhone({ phone: values[PhoneNumberFormFields.Phone] }));
      setLoadingState('success');
      changeStep(SignInProcessSteps.VerificationCodeStep);
    } catch (err) {
      setLoadingState('error');
    }
  };

  const isLoading = loadingState === 'loading';

  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.SignIn} subTitle={PageSubtitles.SignIn} />
      <Formik
        initialValues={{ phone: phone || '' }}
        validationSchema={phoneNumberFormValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors, submitForm, isValid, setFieldValue, setTouched }) => {
          const handleContinue = async () => {
            await submitForm();
          };

          const { handleInputChange, handleTouched, getInputErrorMessage } = createFormikHelpers<
            PhoneNumberFormFields,
            IPhoneNumberFormValues
          >(setFieldValue, setTouched, touched, errors);

          return (
            <section className={styles.contentWrapper}>
              <label className={getInputErrorMessage(PhoneNumberFormFields.Phone) && styles.inputError}>
                Cell Phone 
                <PhoneNumberInput
                  value={phone || ''}
                  onChange={handleInputChange(PhoneNumberFormFields.Phone)}
                  onBlur={handleTouched(PhoneNumberFormFields.Phone)}
                  errormessage={getInputErrorMessage(PhoneNumberFormFields.Phone)}
                  required
                />
                {getInputErrorMessage(PhoneNumberFormFields.Phone) && (
                  <InputError errorMessage={getInputErrorMessage(PhoneNumberFormFields.Phone)!} />
                )}
              </label>
              <div className={styles.buttonsContainer}>
                <Button type={ButtonType.Secondary} className={styles.backBtn} onClick={handleBack}>
                  <LeftArrow />
                </Button>
                <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid}>
                  Get Phone Code
                </Button>
              </div>
            </section>
          );
        }}
      </Formik>
    </div>
  );
};

export default PhoneNumberStep;
