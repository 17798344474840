import { IAuthResponse } from 'api/digifi/auth/AuthTypes';

import BaseApi, { FetchMethodType } from './BaseApi';

export interface IGetCurrentAccountResponse {
  email: string;
  phone: string;
  isEmailVerified: boolean;
  isMfaComplete: boolean;
  isPhoneVerified: boolean;
  intermediaryId: string;
  isBlocked: boolean;
}
export interface IPswValidationToken {
  passwordValidationToken: string;
}

interface ISignInProcessApi {
  sendVerificationCode: (phoneNumber: string) => Promise<void>;

  verifyCode: (code: string) => Promise<IAuthResponse>;

  verifySession: (intermediaryId: string) => Promise<void>;

  logout: () => Promise<void>;

  resetPassword: (email: string) => Promise<void>;

  getCurrentAccount(): Promise<IGetCurrentAccountResponse>;

  getPasswordValidationToken(password: string): Promise<IPswValidationToken>;

}

export default class SignInProcessApi extends BaseApi implements ISignInProcessApi {
  public getCurrentAccount(): Promise<IGetCurrentAccountResponse> {
    return this.fetch('/sign-in-process/account');
  }

  async sendVerificationCode(phone: string): Promise<void> {
    await this.fetch<void>('/sign-in-process/send-verification-code', {
      body: { phone },
      method: FetchMethodType.POST,
    });
  }

  async sendUpdatePhoneCode(phone: string | null, passwordValidationToken: string | null): Promise<void> {
    await this.fetch<void>('/sign-in-process/send-update-account-phone-code', {
      body: { phone, passwordValidationToken },
      method: FetchMethodType.POST,
    });
  }

  async verifyUpdatePhoneCode(phone: string | null, code: string): Promise<void> {
    await this.fetch<void>('/sign-in-process/verify-update-account-phone-code', {
      body: { phone, code },
      method: FetchMethodType.POST,
    });
  }

  async verifyCode(code: string): Promise<IAuthResponse> {
    const response = await this.fetch<IAuthResponse>('/sign-in-process/verify-code', {
      body: { code },
      method: FetchMethodType.POST,
    });

    return response;
  }

  async verifyPassword(email: string, password: string): Promise<IAuthResponse> {
    const response = await this.fetch<IAuthResponse>('/sign-in-process/verify-password', {
      body: { email, password },
      method: FetchMethodType.POST,
    });

    return response;
  }


  async resetPassword(email: string): Promise<void> {
    await this.fetch<IAuthResponse>('/sign-in-process/reset-password', {
      body: { email },
      method: FetchMethodType.POST,
    });
  }

  async createNewPassword(password: string, resetPasswordToken: string): Promise<void> {
    await this.fetch<IAuthResponse>(`/sign-in-process/reset-password/${resetPasswordToken}`, {
      body: { password },
      method: FetchMethodType.PUT,
    });
  }

  async getPasswordValidationToken(password: string): Promise<IPswValidationToken> {
    const response = await this.fetch<IPswValidationToken>('/sign-in-process/password-validation-token', {
      body: { password },
      method: FetchMethodType.POST,
    });
    return response;
  }

  async verifySession(intermediaryId: string): Promise<void> {
    await this.fetch('/sign-in-process/verify-session', {
      method: FetchMethodType.POST,
      body: { intermediaryId },
    });
  }

  async logout(): Promise<void> {
    await this.fetch('/sign-in-process/logout', {
      method: FetchMethodType.DELETE,
    });
  }
}
