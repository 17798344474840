import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationProcessApi from 'api/VerificationProcessApi';
import { VerificationProcessActionType } from 'enums/VerificationProcessActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createVerifyEmailVerificationCode = (api: VerificationProcessApi) =>
  createAsyncThunk(
    VerificationProcessActionType.VerifyEmailVerificationCode,
    async ({code }: {code: string }, thunkApi) => {
      try {
        const { status } = await api.verifyEmailVerificationCode(code);

        return { status };
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
