import React, { DetailedHTMLProps, ButtonHTMLAttributes, FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styles from './ActionPopUpItem.module.scss';

type HTMLButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface IActionPopUpItemProps extends HTMLButtonProps {
  danger?: boolean;
  selected?: boolean;
  href?: string;
  iconClassName?: string;
  onLinkClick?: () => void;
}

const ActionPopUpItem: FC<IActionPopUpItemProps> = ({
  onLinkClick,
  danger,
  disabled,
  className,
  iconClassName,
  children,
  selected,
  href,
  ...props
}) => {
  const buttonStyle = clsx(
    styles.popUpItem,
    danger && styles.dangerButton,
    disabled && styles.disabled,
    selected && styles.selected,
    className,
  );

  return (
    <button
      disabled={disabled}
      type="button"
      className={buttonStyle}
      {...props}
    >
      {children}
    </button>
  );
};

export default ActionPopUpItem;
