import { useEffect, useState } from 'react';
 
const DEFAULT_PORTAL_NAME = 'StandardWholesale';

const useLogoByPortalName = (portalName: string | null = DEFAULT_PORTAL_NAME) => {
  const [logoPath, setLogoPath] = useState('');
  const importIcon = async () => {
    const logo = await import(`images/portalsLogo/${portalName || DEFAULT_PORTAL_NAME}.svg`);
    setLogoPath(logo.default);
  };

  useEffect(() => {
    importIcon();
  }, [portalName]);

  return {
    path: logoPath,
  };
};

export default useLogoByPortalName;
