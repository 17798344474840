import React, { FC } from 'react';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { ReactComponent as Logout } from 'images/logout.svg';

import { removeSessionStorageItem } from 'utils/removeSessionStorageItem';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { useDispatch } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { resetPreQualificationFormData } from 'handlers/prequalificationForm';
import { logout } from 'thunks';
import styles from './LogOut.module.scss';

interface LogOutProps {
  forMobile?: boolean;
}

const LogOut: FC<LogOutProps> = ({ forMobile }) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleLogout = async () => {
    removeSessionStorageItem(SessionStorageKeyName.PreQualificationForm);
    dispatch(resetPreQualificationFormData());
    await dispatchWithUnwrap(logout());
  };

  return (
    forMobile ?
      <Button type={ButtonType.Secondary} className={styles.logout} onClick={handleLogout}>
        <Logout />
      </Button>
      :
      <Button type={ButtonType.Secondary} className={styles.logout} icon={<Logout />} onClick={handleLogout}>
        Log Out
      </Button>
  );
};

export default LogOut;
