import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import TokenUtils from 'utils/TokenUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createVerifySignInPasswordThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.VerifyPassword,
    async ({ email, password }: { email: string; password: string }, thunkApi) => {
    try {
      const { accessToken, refreshToken } = await api.verifyPassword(email, password);

      TokenUtils.setTokens({ accessToken, refreshToken });
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
