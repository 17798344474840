import BaseApi, { FetchMethodType } from './BaseApi';

export enum VerificationCodeStatus {
  Pending = 'pending',
  Approved = 'approved',
  Canceled = 'canceled',
}

export interface IVerifyCodeResponse {
  status: VerificationCodeStatus;
  sendingAttemptsLeft: number;
}

interface IVerificationProcessApi {
  sendPhoneVerificationCode(phone: string): Promise<IVerifyCodeResponse>;
  verifyPhoneVerificationCode(code: string): Promise<IVerifyCodeResponse>;
  sendEmailVerificationCode(phone: string): Promise<IVerifyCodeResponse>;
  verifyEmailVerificationCode(code: string): Promise<IVerifyCodeResponse>;
}

export default class VerificationProcessApi extends BaseApi implements IVerificationProcessApi {
  public sendPhoneVerificationCode(phone: string): Promise<IVerifyCodeResponse> {
    return this.fetch<IVerifyCodeResponse>('/verification-process/send-phone-verification-code', {
      method: FetchMethodType.POST,
      body: {
        phone,
      },
    });
  }

  public verifyPhoneVerificationCode(code: string): Promise<IVerifyCodeResponse> {
    return this.fetch<IVerifyCodeResponse>('/verification-process/verify-phone-verification-code', {
      method: FetchMethodType.POST,
      body: {
        code,
      },
    });
  }

  public sendEmailVerificationCode(email: string): Promise<IVerifyCodeResponse> {
    return this.fetch<IVerifyCodeResponse>('/verification-process/send-email-verification-code', {
      method: FetchMethodType.POST,
      body: {
        email,
      },
    });
  }

  public verifyEmailVerificationCode(
    code: string,
  ): Promise<IVerifyCodeResponse> {
    return this.fetch<IVerifyCodeResponse>('/verification-process/verify-email-verification-code', {
      method: FetchMethodType.POST,
      body: {
        code,
      },
    });
  }
}
