import { createAsyncThunk } from '@reduxjs/toolkit';
import SignInProcessApi from 'api/SignInProcessApi';
import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetPasswordValidationTokenThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.CreatePasswordValidationToken, async (password: string, thunkApi) => {
    try {
      return await api.getPasswordValidationToken(password);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
