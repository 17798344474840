import { PassedProgramsData, PricesToRates } from 'api/PreQualificationApi';
import React, { FC, MutableRefObject, useEffect, useState } from 'react';

import { Application } from 'handlers/prequalificationForm';

import { ProductType } from 'enums/ProductType';

import { usePortalContext } from 'providers/usePortalContext';

import SubmissionForm from './SubmissionForm';

import ProductDetails from './ProductDetails';

import styles from './PrequalificationResultPDF.module.scss';

export interface Theme {
  gradientFirstColor: string;
  gradientSecondColor: string;
  primary100: string;
}

interface PrequalificationResultPDFProps {
  productType: ProductType;
  currentProgram: PassedProgramsData;
  containerReference: MutableRefObject<null>;
  lpcItems: PricesToRates[] | null;
  bpcItems: PricesToRates[];
  values: Application | null;
  currentBpcPriceOption: PricesToRates;
  currentLpcPriceOption: PricesToRates | null;
  changeSelectedBpcRow: (id: string) => void;
  changeSelectedLpcRow: (id: string) => void;
}

const PrequalificationResultPDF: FC<PrequalificationResultPDFProps> = ({
  productType,
  currentProgram,
  containerReference,
  lpcItems,
  bpcItems,
  values,
  currentBpcPriceOption,
  currentLpcPriceOption,
  changeSelectedBpcRow,
  changeSelectedLpcRow,
}) => {
  const { portalName } = usePortalContext();
  const [colors, setColors] = useState<Theme | null>(null);

  const getStyles = async () => {
    const stylesPath = `portalsStyles/${portalName}.json`;

    const fetchedStyles = await fetch(stylesPath);
    const parsedStyles = await fetchedStyles.json();
    setColors(parsedStyles);
  };

  useEffect(() => {
    getStyles();
  }, []);

  return (
    <div className={styles.container} ref={containerReference}>
      {values && <SubmissionForm productType={productType} values={values} colors={colors} />}
      <div className={styles.pageBreak} />
      <ProductDetails
        colors={colors}
        lpcItems={lpcItems}
        bpcItems={bpcItems}
        currentProgram={currentProgram}
        currentBpcPriceOption={currentBpcPriceOption}
        currentLpcPriceOption={currentLpcPriceOption}
        changeSelectedBpcRow={changeSelectedBpcRow}
        changeSelectedLpcRow={changeSelectedLpcRow}
        rowClassName={styles.detailsRow}
      />
    </div>
  );
};

export default PrequalificationResultPDF;
