import React, { FC } from 'react';
import clsx from 'clsx';

import useLogoPathByPortalName from 'hooks/useLogoPathByPortalName';

import { ReactSVG } from 'react-svg';

import { usePortalContext } from 'providers/usePortalContext';
import { PortalName } from 'settings';
import styles from './Header.module.scss';

interface HeaderProps {
  disposedInBlock?: boolean;
  defaultLogo?: boolean;
}

const Header: FC<HeaderProps> = ({ disposedInBlock, defaultLogo }) => {
  const { portalName } = usePortalContext();
  const { path } = useLogoPathByPortalName(defaultLogo ? PortalName.Wholesale : portalName);

  return (
    <div className={clsx(disposedInBlock ? styles.headerInBlock : styles.headerContainer)}>
      <div className={styles.headerLogo}>
        <ReactSVG src={path} />
      </div>
    </div>
  );
};

export default Header;
