import * as Yup from 'yup';

export enum PasswordFormFields {
  Password = 'password',
  ConfirmPassword = 'confirmPassword'
}

export interface IPasswordFormValues {
  [PasswordFormFields.Password]: string;
  [PasswordFormFields.ConfirmPassword]: string;
}

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
// min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit, 1 special character

export const passwordStepValidationSchema = Yup.object({
  password: Yup
    .string()
    .required('Password is required')
    .matches(passwordRules, 'The password must be at least 8 characters long and include at least one upper case letter, one lower case letter, one number and one special character.'),
  confirmPassword: Yup
    .string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match. Please ensure both fields contain the exact same password and try again.')
  });
