import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationProcessApi from 'api/VerificationProcessApi';
import { VerificationProcessActionType } from 'enums/VerificationProcessActionType';

export const createSendEmailVerificationCodeThunk = (api: VerificationProcessApi) =>
  createAsyncThunk(
    VerificationProcessActionType.SendEmailVerificationCode,
    async ({ email }: { email: string }) => {
      const response = await api.sendEmailVerificationCode(email);
      return response;
    },
  );
