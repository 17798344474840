import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { searchAccountsByEmail, searchIntermediariesByEmail } from 'thunks';

import { getSignInProcessState } from 'handlers/selectors';
import { setAccountExists, setEmail, setIntermediaryId } from 'handlers/signInProcessSlice';

import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useLoadingState } from 'hooks/useLoadingState';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import Input from 'components/Input';
import { ButtonType } from 'components/Button/Button';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';

import InputError from 'components/InputError';

import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';
import { PageErrors } from 'enums/PageErrors';

import { createFormikHelpers } from 'utils/formHelpers';

import RequestError from 'errors/RequestError';

import {
  IEmailFormValues,
  EmailFormFields,
  emailFormValidationSchema,
} from 'validationSchemas/emailStepValidation';

import styles from './SignInProcessStep.module.scss';

const CreateAccountEmailStep = ({ changeStep }: ISignInStepProps) => {
  const { email } = useAppSelector(getSignInProcessState);
  const [emailError, setEmailError] = useState<string>('');
  const [creationDisabled, setCreationDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [loadingState, setLoadingState] = useLoadingState();

  const handleSubmit = async (values: IEmailFormValues) => {
    try {
      setLoadingState('loading');
      dispatch(setEmail({ email: values[EmailFormFields.Email] }));
      const { isAccountExists } = await dispatchWithUnwrap(searchAccountsByEmail(values[EmailFormFields.Email]));

      if (!isAccountExists) {
        const { intermediaryId, isIntermediaryExists } = await dispatchWithUnwrap(
          searchIntermediariesByEmail(values[EmailFormFields.Email]),
        );

        if (!isIntermediaryExists) {
          setLoadingState('error');
          setCreationDisabled(true);
          setEmailError(PageErrors.CreateAccount);
          return;
        }
        dispatch(setIntermediaryId({ intermediaryId }));

        setLoadingState('success');
        return changeStep(SignInProcessSteps.CreateAccountPhoneStep);
      }
      dispatch(setAccountExists({ accountExists: isAccountExists }));
      setLoadingState('success');
      setCreationDisabled(isAccountExists);
      setEmailError(PageErrors.AccountExists);
    } catch (err) {
      const error = err as RequestError;

      if (error.message) {
        setEmailError(PageErrors.CreateAccount);
      }
      setLoadingState('error');
    }
  };

  const isLoading = loadingState === 'loading';

  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.CreateAccount} subTitle={PageSubtitles.CreateAccountEmail} />
      <Formik
        initialValues={{ email }}
        validationSchema={emailFormValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >

        {({ touched, errors, submitForm, isValid, setFieldValue, setTouched, values }) => {
          const handleContinue = async () => {
            await submitForm();
          };

          const { handleInputChange, handleTouched, getInputErrorMessage, handleResetExternalError } = createFormikHelpers<
            EmailFormFields,
            IEmailFormValues
          >(setFieldValue, setTouched, touched, errors, { email: emailError },
            () => setEmailError(''));

          return (
            <section className={styles.contentWrapper}>
              <label className={getInputErrorMessage(EmailFormFields.Email) && styles.inputError}>
                Email
                <Input
                  value={values[EmailFormFields.Email]}
                  onChange={(e) => {
                    setCreationDisabled(false);
                    handleInputChange(EmailFormFields.Email)(e.target.value);
                  }}
                  onBlur={handleTouched(EmailFormFields.Email)}
                  onFocus={handleResetExternalError(EmailFormFields.Email)}
                  required
                  placeholder='email@domain.com'
                />
                {getInputErrorMessage(EmailFormFields.Email) && (
                  <InputError errorMessage={getInputErrorMessage(EmailFormFields.Email)!} />
                )}
              </label>
              <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid || creationDisabled}>
                Create Account
              </Button>
              <div>
                Already have an account?
                <Button type={ButtonType.Secondary} onClick={() => changeStep(SignInProcessSteps.OptionsStep)}>
                  Sign In
                </Button>
              </div>
            </section>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAccountEmailStep;
