import React, { useState, DetailedHTMLProps, InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import useRandomIdFallback from 'hooks/randomIdFallback';
import clsx from 'clsx';
import styles from './PasswordInput.module.scss';



type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface InputProps extends Omit<HTMLInputProps, 'ref'> {
  id?: string;
  containerClassName?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (
    { id: providedId, onChange, value, containerClassName, required, disabled, onFocus, onBlur, ...inputProps },
    ref,
  ) => {
    const id = useRandomIdFallback(providedId);
    const [isVisible, setVisible] = useState(false);
    const [isFocused, setFocus] = useState(false);
    const toggleFocusInput = () => {
      setFocus(!isFocused);
    };
    const toggleVisibility = () => {
      setVisible(!isVisible);
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      toggleFocusInput();
      onFocus?.(e);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      toggleFocusInput();
      onBlur?.(e);
    }

    return (
      <div className={clsx(styles.inputContainer, containerClassName)}>
          <input
            type={isVisible ? 'text' : 'password'}
            id={id}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            autoComplete="none"
            className={styles.input}
            ref={ref}
            disabled={disabled}
            {...inputProps}
          />
          <button
            type="button"
            tabIndex={-1}
            className={clsx(isVisible ? styles.hideButton : styles.showButton, { [styles.disabledButton]: disabled })}
            onClick={toggleVisibility}
            disabled={disabled}
          >
            {' '}
          </button>
    
      </div>
    );
  },
);

export default PasswordInput;
