export enum PageTitles {
 CreateAccount = 'Create Account',
 SignIn = 'Sign In',
 VerifyEmail = 'Verify Your Email',
 VerifyPhone = 'Verify Your Phone Number',
 ResetPassword = 'Reset Password',
 ResetPasswordSuccess = 'Password Successfully Updated',
 Sorry = 'We are sorry',
 CreateUnsuccess = 'Account Creating Unsuccessful',
 ChangePhone = 'Change Phone Number'
}
