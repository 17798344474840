import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationProcessApi from 'api/VerificationProcessApi';
import { VerificationProcessActionType } from 'enums/VerificationProcessActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createVerifyPhoneVerificationCodeThunk = (api: VerificationProcessApi) =>
  createAsyncThunk(
    VerificationProcessActionType.VerifyPhoneVerificationCode,
    async ({ code }: { code: string }, thunkApi) => {
      try {
        const response = await api.verifyPhoneVerificationCode(code);
        return response;
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
