import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { createAccount, createNewPassword } from 'thunks';

import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { getSignInProcessState } from 'handlers/selectors';

import { ReactComponent as LeftArrow } from 'images/arrow-left.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormTitle from 'components/FormTitle';
import PasswordInput from 'components/PasswordInput';
import InputError from 'components/InputError';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';
import { setNeedPhoneVerification, setNeedVerification } from 'handlers/signInProcessSlice';
import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';
import { PageErrors } from 'enums/PageErrors';
import RequestError from 'errors/RequestError';
import { createFormikHelpers } from 'utils/formHelpers';

import {
  IPasswordFormValues,
  PasswordFormFields,
  passwordStepValidationSchema,
} from 'validationSchemas/passwordStepValidation';

import styles from './SignInProcessStep.module.scss';

const CreatePasswordStep = ({ changeStep, resetPassword }: ISignInStepProps) => {
  const [loadingState, setLoadingState] = useLoadingState();
  const [passwordError, setPasswordError] = useState<string>('');
  const { intermediaryId, phone } = useAppSelector(getSignInProcessState);
  const history = useHistory();
  const token = window.location.pathname.split('/').reverse()[0];
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();

  const handleSubmit = async (values: IPasswordFormValues) => {
    try {
      setLoadingState('loading');
      if (resetPassword) {
        await dispatchWithUnwrap(
          createNewPassword({
            password: values[PasswordFormFields.Password],
            resetPasswordToken: token || '',
          }),
        );
        changeStep(SignInProcessSteps.ResetPasswordSuccessfulStep);
        history.push('/');
        return;
      }

      await dispatchWithUnwrap(
        createAccount({
          password: values[PasswordFormFields.Password],
          intermediaryId: intermediaryId!,
          phone
        }),
      );

      setLoadingState('success');
      dispatch(setNeedVerification({ needVerification: true }));
      dispatch(setNeedPhoneVerification({ needPhoneVerification: !!phone }));
      return changeStep(SignInProcessSteps.VerificationCodeStep);
    } catch (err) {
      const error = err as RequestError;
      setLoadingState('error');
      if (error.responseStatus === 400) {
        setPasswordError(resetPassword ? error.message : PageErrors.TechnicalIssue);
        return;
      }
      setPasswordError(PageErrors.TechnicalIssue);

    }
  };

  const isLoading = loadingState === 'loading';

  return (
    <div className={styles.formWrapper}>
      <FormTitle title={resetPassword ? PageTitles.ResetPassword : PageTitles.CreateAccount} subTitle={resetPassword ? PageSubtitles.NewPassword : PageSubtitles.CreateAccountPassword} />
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={passwordStepValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors, submitForm, isValid, setTouched, setFieldValue, values }) => {

          const handleContinue = async () => {
            await submitForm();

          };

          const { handleInputChange, handleTouched, getInputErrorMessage, handleResetExternalError } =
            createFormikHelpers<PasswordFormFields, IPasswordFormValues>(
              setFieldValue,
              setTouched,
              touched,
              errors,
              { password: passwordError },
              () => setPasswordError(''),
            );
          return (
            <section className={styles.contentWrapper}>
              <div>
                <label className={getInputErrorMessage(PasswordFormFields.Password) && styles.inputError}>
                  Password
                  <PasswordInput
                    name="password"
                    required
                    value={values[PasswordFormFields.Password]}
                    onChange={(e) => handleInputChange(PasswordFormFields.Password)(e.target.value)}
                    onBlur={handleTouched(PasswordFormFields.Password)}
                    onFocus={handleResetExternalError(PasswordFormFields.Password)}
                    disabled={isLoading}
                    autoComplete="password"
                  />
                  {getInputErrorMessage(PasswordFormFields.Password) && (
                    <InputError errorMessage={getInputErrorMessage(PasswordFormFields.Password)!} />
                  )}
                </label>
              </div>
              <div>
                <label className={getInputErrorMessage(PasswordFormFields.ConfirmPassword) && styles.inputError}>
                  Confirm password
                  <PasswordInput
                    name="confirm-password"
                    required
                    value={values[PasswordFormFields.ConfirmPassword]}
                    onChange={(e) => handleInputChange(PasswordFormFields.ConfirmPassword)(e.target.value)}
                    onBlur={handleTouched(PasswordFormFields.ConfirmPassword)}
                    onFocus={handleResetExternalError(PasswordFormFields.ConfirmPassword)}
                    disabled={isLoading}
                    autoComplete="new-password"
                  />
                  {getInputErrorMessage(PasswordFormFields.ConfirmPassword) && (
                    <InputError errorMessage={getInputErrorMessage(PasswordFormFields.ConfirmPassword)!} />
                  )}
                </label>
              </div>
              <div className={styles.buttonsContainer}>
                {!resetPassword &&
                  <Button type={ButtonType.Secondary} className={styles.backBtn} onClick={() => changeStep(SignInProcessSteps.CreateAccountPhoneStep)}>
                    <LeftArrow />
                  </Button>
                }
                <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid}>
                  {resetPassword ? 'Reset Password' : 'Next'}
                </Button>
              </div>
            </section>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreatePasswordStep;
