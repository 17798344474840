import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { PrequalificationVariable } from 'enums/PrequalificationVariable';
import { ProductType } from 'enums/ProductType';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { getSessionStorageItem } from 'utils/getSessionStorageItem';

export interface Application {
  [PrequalificationInputName.LoanPurpose]: string;
  [PrequalificationInputName.PurchasePriceOrEstimatedValue]: string;
  [PrequalificationInputName.LoanToValue]: string;
  [PrequalificationInputName.LoanAmount]: string;
  [PrequalificationInputName.Reserves]: string;
  [PrequalificationInputName.DebtToIncome]: string;
  [PrequalificationInputName.DebtServiceCoverageRatio]: string;
  [PrequalificationInputName.IncomeDocumentType]: string;
  [PrequalificationInputName.InterestOnly]: string;
  [PrequalificationInputName.Citizenship]: string;
  [PrequalificationInputName.PrepaymentPenalty]: string;
  [PrequalificationInputName.EscrowWaiver]: string;
  [PrequalificationInputName.CreditScore]: string;
  [PrequalificationInputName.MortgagePaymentHistory]: string;
  [PrequalificationInputName.CreditEvents]: string;
  [PrequalificationInputName.PropertyType]: string;
  [PrequalificationInputName.Occupancy]: string;
  [PrequalificationInputName.Vesting]: string;
  [PrequalificationInputName.State]: string;
  [PrequalificationInputName.County]: string;
  [PrequalificationInputName.SelfEmployed]: string;
  [PrequalificationInputName.LenderPaidCompensation]: string;
  [PrequalificationInputName.CompanyName]: string;
}

export interface CoercedApplication {
  [PrequalificationVariable.LoanPurpose]: string;
  [PrequalificationVariable.PurchasePriceOrEstimatedValue]: number;
  [PrequalificationVariable.LoanToValue]: number;
  [PrequalificationVariable.LoanAmount]: number;
  [PrequalificationVariable.Reserves]: string;
  [PrequalificationVariable.DebtToIncome]: number | null;
  [PrequalificationVariable.DebtServiceCoverageRatio]: number | null;
  [PrequalificationVariable.IncomeDocumentType]: string | null;
  [PrequalificationVariable.InterestOnly]: string;
  [PrequalificationVariable.Citizenship]: string;
  [PrequalificationVariable.PrepaymentPenalty]: string | null;
  [PrequalificationVariable.EscrowWaiver]: string;
  [PrequalificationVariable.CreditScore]: number;
  [PrequalificationVariable.MortgagePaymentHistory]: string;
  [PrequalificationVariable.CreditEvents]: string;
  [PrequalificationVariable.PropertyType]: string;
  [PrequalificationVariable.Occupancy]: string;
  [PrequalificationVariable.Vesting]: string;
  [PrequalificationVariable.State]: string;
  [PrequalificationVariable.County]: string;
  [PrequalificationVariable.SelfEmployed]: string;
  [PrequalificationVariable.LenderPaidCompensation]: number | null | undefined;
  [PrequalificationVariable.CompanyName]: string;
}

export interface PreQualificationForm {
  application: Application;
  coercedApplication: null | CoercedApplication;
  productType: ProductType;
  intermediaryPriceAdjustment: number | null;
}

export const initialEmptyState: PreQualificationForm = {
  application: {
    [PrequalificationInputName.LoanPurpose]: '',
    [PrequalificationInputName.PurchasePriceOrEstimatedValue]: '',
    [PrequalificationInputName.LoanToValue]: '',
    [PrequalificationInputName.LoanAmount]: '',
    [PrequalificationInputName.Reserves]: '',
    [PrequalificationInputName.DebtToIncome]: '',
    [PrequalificationInputName.DebtServiceCoverageRatio]: '',
    [PrequalificationInputName.IncomeDocumentType]: '',
    [PrequalificationInputName.InterestOnly]: '',
    [PrequalificationInputName.Citizenship]: '',
    [PrequalificationInputName.PrepaymentPenalty]: '',
    [PrequalificationInputName.EscrowWaiver]: '',
    [PrequalificationInputName.CreditScore]: '',
    [PrequalificationInputName.MortgagePaymentHistory]: '',
    [PrequalificationInputName.CreditEvents]: '',
    [PrequalificationInputName.PropertyType]: '',
    [PrequalificationInputName.Occupancy]: '',
    [PrequalificationInputName.Vesting]: '',
    [PrequalificationInputName.State]: '',
    [PrequalificationInputName.County]: '',
    [PrequalificationInputName.SelfEmployed]: '',
    [PrequalificationInputName.LenderPaidCompensation]: '',
    [PrequalificationInputName.CompanyName]:''
  },
  coercedApplication: null,
  productType: ProductType.AlternativeDocumentation,
  intermediaryPriceAdjustment: null,
};

const preQualificationFormInitialState = getSessionStorageItem(SessionStorageKeyName.PreQualificationForm);

const initialState: PreQualificationForm = preQualificationFormInitialState ?? initialEmptyState;

const preQualificationFormReducer = createSlice({
  name: 'preQualificationForm',
  initialState,
  reducers: {
    setPreQualificationFormData: (state: PreQualificationForm, { payload }: PayloadAction<PreQualificationForm>) => {
      state = payload;

      return state;
    },

    setLenderPaidCompensationData: (state: PreQualificationForm, { payload }: PayloadAction<string>) => {
      state.application[PrequalificationInputName.LenderPaidCompensation] = payload;
     
      return state ;
    },
  
    resetPreQualificationFormData: (state: PreQualificationForm) => {
      let lenderPaidCompensation = '';
      if(state.application[PrequalificationInputName.CompanyName] === ''){
        lenderPaidCompensation = state.application[PrequalificationInputName.LenderPaidCompensation];
      }
      state = {
        ...initialEmptyState,
        application: {...initialEmptyState.application},
      }
      state.application[PrequalificationInputName.LenderPaidCompensation] = lenderPaidCompensation;
      
      return state;
    },
  },
});

export const { setPreQualificationFormData, resetPreQualificationFormData, setLenderPaidCompensationData } = preQualificationFormReducer.actions;

export default preQualificationFormReducer.reducer;
