import { createAsyncThunk } from '@reduxjs/toolkit';
import SignInProcessApi from 'api/SignInProcessApi';
import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createVerifyUpdatePhoneCodeThunk = (api: SignInProcessApi) =>
  createAsyncThunk(
    SignInProcessActionTypes.VerifyUpdatePhoneVerificationCode,
    async ({ phone, code }: { phone: string | null, code: string }, thunkApi) => {
      try {
        await api.verifyUpdatePhoneCode(phone, code);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
