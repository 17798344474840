import * as Yup from 'yup';

export enum VerificationCodeFormFields {
  Code = 'code',
}

export interface IVerificationCodeFormValues {
  [VerificationCodeFormFields.Code]: string;
}

export const verificationCodeValidationSchema = Yup.object().shape({
  code: Yup.string().required('Verification code is required').length(6, 'Verification code must be 6 characters long'),
});
