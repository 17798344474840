import { createAsyncThunk } from '@reduxjs/toolkit';
import { PreQualificationApi } from 'api/PreQualificationApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCheckAvailabilityThunk = (api: PreQualificationApi) =>
  createAsyncThunk('preQualification/checkPricingAvailability',
     async ({ intermediaryId, companyId}: { intermediaryId: string, companyId?: string | null}, thunkApi) => {
    try{
      const сheckAvailabilityResult = await api.checkPricingAvailability(intermediaryId, companyId);
      return сheckAvailabilityResult;
    } catch (error){
      return rejectWithValueHelper(error as Error, thunkApi);
    }  
  });

  
