export const LATIN_LETTERS_SPACE_DASH_REGEX = /[^A-Za-z\s'-]+/gim;
export const NOT_CYRILLIC_REGEX = /[\u0400-\u04FF]/gim;
export const NOT_DIGITS_REGEX = /\D/gim;

export const formatString = (regEx: RegExp) => (value: string) => value.replace(regEx, '');

export const unFormatBorrowerPhone = (phone: string) => {
  const reg = new RegExp(NOT_DIGITS_REGEX, 'g');
  const replaced = phone.replace(reg, '');

  if (!replaced) {
    return '';
  }

  return `+1${phone.replace(reg, '')}`;
};
