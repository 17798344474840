import { createAsyncThunk } from '@reduxjs/toolkit';
import SignUpProcessApi from 'api/SignUpProcessApi';
import { SignUpProcessActionTypes } from 'enums/SignUpProcessActionTypes';
import TokenUtils from 'utils/TokenUtils';

export const createCreateAccountThunk = (api: SignUpProcessApi) =>
  createAsyncThunk(
    SignUpProcessActionTypes.CreateAccount,
    async ({ password, intermediaryId, phone  }: { password: string, intermediaryId: string, phone: string | null}) => {
      const response = await api.createAccount(password, intermediaryId, phone);

      const { accessToken, refreshToken } = response;

      TokenUtils.setTokens({ accessToken, refreshToken });

      return response;
    },
  );
