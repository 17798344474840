import * as Yup from 'yup';

export enum IChangePhonePasswordFields {
  Password = 'password',
}
export interface IChangePhonePasswordFormValues {
  [IChangePhonePasswordFields.Password]: string;
}

export const changePhoneStepValidationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required')
});
