export enum SignInProcessSteps {
  OptionsStep = 'OptionsStep',
  SignInStep = 'SignInStep',
  SignInByEmailStep = 'SignInByEmailStep',
  VerificationCodeStep = 'VerificationCodeStep',
  CreateAccountPhoneStep = 'CreateAccountPhoneStep',
  CreateAccountEmailStep = 'CreateAccountEmailStep',
  CreatePasswordStep = 'CreatePasswordStep',
  UnsuccessfulStep = 'UnsuccessfulStep',
  ResetPasswordStep = 'ResetPasswordStep',
  ResetPasswordInfoStep = 'ResetPasswordInfoStep',
  ResetPasswordSuccessfulStep = 'ResetPasswordSuccessfulStep',
}
