import React from 'react';
import { ReactComponent as ExperineceLogo } from 'images/experience_logo.svg';
import styles from './SignInRightBar.module.scss';

const SignInRightBar = () => (
  <>
    <div className={styles.container}>
      <div className={styles.title}>
        <ExperineceLogo />
      </div>
      
    </div>
  </>
);

export default SignInRightBar;
