import React, { FC } from 'react';

import styles from './FormTitle.module.scss';

interface IFormTitleProps {
  title: string;
  subTitle?: string | JSX.Element;
}

const FormTitle: FC<IFormTitleProps> = ({ title, subTitle }) => {
  return (
    <>
      <div className={styles.formTitle}>{title}</div>
      {subTitle && <div className={styles.formSubtitle}>{subTitle}</div>}
    </>
  );
};

export default FormTitle;
