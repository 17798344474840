import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationProcessApi from 'api/VerificationProcessApi';
import { VerificationProcessActionType } from 'enums/VerificationProcessActionType';

export const createSendPhoneVerificationCodeThunk = (api: VerificationProcessApi) =>
  createAsyncThunk(
    VerificationProcessActionType.SendPhoneVerificationCode,
    async ({ phone }: {phone: string }) => {
      const response = await api.sendPhoneVerificationCode(phone);
      return response;
    },
  );
