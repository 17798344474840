import * as Yup from 'yup';

export enum EmailFormFields {
  Email = 'email',
}
export interface IEmailFormValues {
  [EmailFormFields.Email]: string;
}

export const emailFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address.').required('Email is required'),
});
