import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Formik } from 'formik';

import { verifyPassword } from 'thunks';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';

import { getSignInProcessState } from 'handlers/selectors';

import { ReactComponent as LeftArrow } from 'images/arrow-left.svg';

import InputError from 'components/InputError';
import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import { ButtonType } from 'components/Button/Button';
import PasswordInput from 'components/PasswordInput';
import Input from 'components/Input';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';

import { createFormikHelpers } from 'utils/formHelpers';

import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';

import {
  IEmailSignInFormValues,
  EmailSignInFormFields,
  emailSignInFormValidationSchema,
} from 'validationSchemas/emailSignInStepValidation';

import styles from './SignInProcessStep.module.scss';

const EmailSignInStep = ({ changeStep }: ISignInStepProps) => {
  const { email } = useAppSelector(getSignInProcessState);
  const [loadingState, setLoadingState] = useLoadingState();
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [signInDisabled, setSignInDisabled] = useState<boolean>(false);
  const history = useHistory();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleBack = () => changeStep(SignInProcessSteps.OptionsStep);

  const handleSubmit = async (values: IEmailSignInFormValues) => {
    try {
      setLoadingState('loading');
      await dispatchWithUnwrap(verifyPassword({
        email: values[EmailSignInFormFields.Email],
        password: values[EmailSignInFormFields.Password],
      }));

      setLoadingState('success');
      history.push('/');
    } catch {
      // CURO-TODO add error handling
      setSignInDisabled(true);
      setEmailError(' ');
      setPasswordError('Invalid email or password. Please try again.');
      setLoadingState('error');
    }
  };

  const isLoading = loadingState === 'loading';

  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.SignIn} subTitle={PageSubtitles.SignIn} />
      <Formik
        initialValues={{ email, password: '' }}
        validationSchema={emailSignInFormValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors, submitForm, isValid, setFieldValue, setTouched, values }) => {
          const handleContinue = async () => {
            await submitForm();
          };

          const { handleInputChange, handleTouched, getInputErrorMessage, handleResetExternalError } = createFormikHelpers<
            EmailSignInFormFields,
            IEmailSignInFormValues
          >(setFieldValue, setTouched, touched, errors,  { email: emailError, password: passwordError }, ()=> {
            setEmailError('');
            setPasswordError('');
        });

          return (
            <section className={styles.contentWrapper}>
              <label className={getInputErrorMessage(EmailSignInFormFields.Email) && styles.inputError}>
                Email
                <Input
                  value={values[EmailSignInFormFields.Email]}
                  onChange={(e) => {
                    setSignInDisabled(false);
                    handleInputChange(EmailSignInFormFields.Email)(e.target.value);
                  }}
                  onBlur={handleTouched(EmailSignInFormFields.Email)}
                  onFocus={handleResetExternalError(EmailSignInFormFields.Email)}
                  required
                  placeholder='email@domain.com'
                />
                {getInputErrorMessage(EmailSignInFormFields.Email) && (
                  <InputError errorMessage={getInputErrorMessage(EmailSignInFormFields.Email)!} />
                )}
              </label>
              <label className={getInputErrorMessage(EmailSignInFormFields.Password) && styles.inputError}>
                Password
                <PasswordInput
                  name="password"
                  required
                  value={values[EmailSignInFormFields.Password]}
                  onChange={(e) => {
                    setSignInDisabled(false);
                    handleInputChange(EmailSignInFormFields.Password)(e.target.value);
                  }}
                  onBlur={handleTouched(EmailSignInFormFields.Password)}
                  onFocus={handleResetExternalError(EmailSignInFormFields.Password)}
                  disabled={isLoading}
                  autoComplete="password"
                />
                {getInputErrorMessage(EmailSignInFormFields.Password) && (
                  <InputError errorMessage={getInputErrorMessage(EmailSignInFormFields.Password)!} />
                )}
              </label>
              <div className={styles.buttonsContainer}>
                <Button type={ButtonType.Secondary} className={styles.backBtn} onClick={handleBack}>
                  <LeftArrow />
                </Button>
                <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid || signInDisabled}>
                  Sign In
                </Button>
              </div>
              <div className={styles.resetContainer} onClick={() => changeStep(SignInProcessSteps.ResetPasswordStep)}>
                <span className={styles.title}>Reset Password</span>
              </div>
            </section>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmailSignInStep;
