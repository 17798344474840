import React, { useEffect, useState } from 'react';

import SignInLeftBar from 'components/SignInLeftBar';
import SignInRightBar from 'components/SignInRightBar';
import { useDispatch } from 'react-redux';
import useThemeByPortalType from 'hooks/useThemeByPortalType';
import { setIsStylesLoaded } from 'handlers/isStylesLoaded';
import { PortalName } from 'settings';
import PageLoader from 'components/PageLoader';
import styles from './SignInPage.module.scss';

const SignInPage = () => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useThemeByPortalType(PortalName.Wholesale).then(([loading]) => setIsLoaded(!loading));

  useEffect(() => {
     dispatch(setIsStylesLoaded(false));
  }, []);


  return (
   isLoaded  ? ( 
      <div className={styles.wrapper}>
        <SignInLeftBar />
        <SignInRightBar />
        <div className={styles.footer}>
          <p>{new Date().getFullYear()} © Logan Finance Corporation. All rights reserved.</p>
        </div>
      </div>
    ) : <PageLoader/> 
  );
};

export default SignInPage;
