import React, { FC, useRef, useState } from 'react';

import clsx from 'clsx';
import MyAccount from 'components/MyAccount';
import AccountMenu from 'components/AccountMenu';
import Header from 'components/Header';
import Footer from 'components/Footer';

import styles from './InnerPageLayout.module.scss';


interface InnerPageLayoutProps {
  className?: string;
}

const InnerPageLayout: FC<InnerPageLayoutProps> = ({ children, className }) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [menuState, setMenuState] = useState<boolean>(false);

  const renderMenu = () => {    
    return (
      <AccountMenu
        onClose={() => setMenuState(false)}
        anchorElement={headerRef?.current}
        isMobile
      />
    );
};

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
          <div className={styles.headerBar}>
          <Header />
          {menuState && renderMenu()}
          <div className={styles.logoutInHeader}>
          <MyAccount ref={headerRef} clickEvent={()=>{
              setMenuState(true);
            }}/>
          </div>
        </div>
      </div>
      <div className={clsx(styles.content, className)}>{children}</div>
      <Footer isInsidePage />
    </div>
  );
};

export default InnerPageLayout;
