import React from 'react';
import { FormikValues } from 'formik';
import { coerceNumberToDisplayValue } from 'utils/coerceNumberToDisplayValue';
import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import NumberInput from 'components/NumberInput';
import styles from './LenderInformation.module.scss';


interface LenderInformationProps {
  formikValues: FormikValues;
}

const LenderInformation = ({ formikValues }: LenderInformationProps) => {
  return (
    <div>
      <h3 className={styles.sectionTitle}>Lender Information</h3>
      <NumberInput
        label={PrequalificationInputLabel.LenderPaidCompensation}
        value={formikValues[PrequalificationInputName.LenderPaidCompensation] && coerceNumberToDisplayValue(Math.abs(formikValues[PrequalificationInputName.LenderPaidCompensation])).toString()}
        name={PrequalificationInputName.LenderPaidCompensation}
        className={styles.formInput}
        decimalPlacesAmount={3}
        disabled
      />
    </div>
  );
};

export default LenderInformation;
