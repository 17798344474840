import React, { FC, useEffect } from 'react';

import { Redirect, useHistory } from 'react-router';

import TokenUtils from 'utils/TokenUtils';

import { getCurrentAccount } from 'thunks';

import { getSignInProcessState } from 'handlers/selectors';

import { useAppSelector } from 'hooks/reduxHooks';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { useLoadingState } from 'hooks/useLoadingState';

import PageLoader from 'components/PageLoader';

const RequiredAuth: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useLoadingState();
  const { accountData } = useAppSelector(getSignInProcessState);

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const history = useHistory();

  const checkSession = async () => {
    const accessToken = TokenUtils.getAccessToken();
    const refreshToken = TokenUtils.getRefreshToken();

    if (!accessToken && !refreshToken) {
      setIsLoading('error');
      return;
    }

    setIsLoading('loading');
    try {
      if (!accountData) {
        await dispatchWithUnwrap(getCurrentAccount());
      }

      setIsLoading('success');
    } catch (error) {
      setIsLoading('error');
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  useEffect(() => {
    if (!accountData && isLoading !== 'idle') {
      history.push('/sign-in');
    }else if(accountData){
      if (!accountData?.intermediaryId) {
        return history.push('/sign-in');
      }
      if(!accountData.isEmailVerified){
        return history.push('/sign-in');
      }
      if(accountData.isPhoneVerified === false){
        return history.push('/sign-in');
      }
      if(accountData.isBlocked){
        TokenUtils.removeTokens();
        return history.push('/sign-in');
      }
    }
   
  }, [accountData]);

  if (!accountData) {
    if (isLoading === 'idle' || isLoading === 'loading') {
      return <PageLoader />;
    }

    return <Redirect to="/sign-in" />;
  }

  return <>{children}</>;
};

export default RequiredAuth;
