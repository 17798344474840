import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { PrequalificationVariable } from 'enums/PrequalificationVariable';

interface MappingVariable {
  name: PrequalificationInputName;
  label: PrequalificationInputLabel;
  systemName: PrequalificationVariable;
}

export interface Mapping {
  title: string;
  variables: MappingVariable[];
}

export const variablesMapping: Mapping[] = [
  {
    title: 'Account',
    variables: [{
      name: PrequalificationInputName.CompanyName,
      label: PrequalificationInputLabel.CompanyName,
      systemName: PrequalificationVariable.CompanyName,
    }]
  },
  {
    title: 'Loan Criteria',
    variables: [
      {
        name: PrequalificationInputName.LoanPurpose,
        label: PrequalificationInputLabel.LoanPurpose,
        systemName: PrequalificationVariable.LoanPurpose,
      },
      {
        name: PrequalificationInputName.PurchasePriceOrEstimatedValue,
        label: PrequalificationInputLabel.PurchasePriceOrEstimatedValue,
        systemName: PrequalificationVariable.PurchasePriceOrEstimatedValue,
      },
      {
        name: PrequalificationInputName.LoanToValue,
        label: PrequalificationInputLabel.LoanToValue,
        systemName: PrequalificationVariable.LoanToValue,
      },
      {
        name: PrequalificationInputName.LoanAmount,
        label: PrequalificationInputLabel.LoanAmount,
        systemName: PrequalificationVariable.LoanAmount,
      },
      {
        name: PrequalificationInputName.Reserves,
        label: PrequalificationInputLabel.Reserves,
        systemName: PrequalificationVariable.Reserves,
      },
      {
        name: PrequalificationInputName.SelfEmployed,
        label: PrequalificationInputLabel.SelfEmployed,
        systemName: PrequalificationVariable.SelfEmployed,
      },
      {
        name: PrequalificationInputName.DebtToIncome,
        label: PrequalificationInputLabel.DebtToIncome,
        systemName: PrequalificationVariable.DebtToIncome,
      },
      {
        name: PrequalificationInputName.DebtServiceCoverageRatio,
        label: PrequalificationInputLabel.DebtServiceCoverageRatio,
        systemName: PrequalificationVariable.DebtServiceCoverageRatio,
      },
      {
        name: PrequalificationInputName.IncomeDocumentType,
        label: PrequalificationInputLabel.IncomeDocumentType,
        systemName: PrequalificationVariable.IncomeDocumentType,
      },
      {
        name: PrequalificationInputName.InterestOnly,
        label: PrequalificationInputLabel.InterestOnly,
        systemName: PrequalificationVariable.InterestOnly,
      },
      {
        name: PrequalificationInputName.Citizenship,
        label: PrequalificationInputLabel.Citizenship,
        systemName: PrequalificationVariable.Citizenship,
      },
      {
        name: PrequalificationInputName.PrepaymentPenalty,
        label: PrequalificationInputLabel.PrepaymentPenalty,
        systemName: PrequalificationVariable.PrepaymentPenalty,
      },
      {
        name: PrequalificationInputName.EscrowWaiver,
        label: PrequalificationInputLabel.EscrowWaiver,
        systemName: PrequalificationVariable.EscrowWaiver,
      },
    ],
  },
  {
    title: 'Credit Profile',
    variables: [
      {
        name: PrequalificationInputName.CreditScore,
        label: PrequalificationInputLabel.CreditScore,
        systemName: PrequalificationVariable.CreditScore,
      },
      {
        name: PrequalificationInputName.MortgagePaymentHistory,
        label: PrequalificationInputLabel.MortgagePaymentHistory,
        systemName: PrequalificationVariable.MortgagePaymentHistory,
      },
      {
        name: PrequalificationInputName.CreditEvents,
        label: PrequalificationInputLabel.CreditEvents,
        systemName: PrequalificationVariable.CreditEvents,
      },
    ],
  },
  {
    title: 'Property Information',
    variables: [
      {
        name: PrequalificationInputName.PropertyType,
        label: PrequalificationInputLabel.PropertyType,
        systemName: PrequalificationVariable.PropertyType,
      },
      {
        name: PrequalificationInputName.Occupancy,
        label: PrequalificationInputLabel.Occupancy,
        systemName: PrequalificationVariable.Occupancy,
      },
      {
        name: PrequalificationInputName.Vesting,
        label: PrequalificationInputLabel.Vesting,
        systemName: PrequalificationVariable.Vesting,
      },
      {
        name: PrequalificationInputName.State,
        label: PrequalificationInputLabel.State,
        systemName: PrequalificationVariable.State,
      },
      {
        name: PrequalificationInputName.County,
        label: PrequalificationInputLabel.County,
        systemName: PrequalificationVariable.County,
      },
    ],
  },
  {
    title: 'Lender Information',
    variables: [
      {
        name: PrequalificationInputName.LenderPaidCompensation,
        label: PrequalificationInputLabel.LenderPaidCompensation,
        systemName: PrequalificationVariable.LenderPaidCompensation,
      },
    ],
  },
];
