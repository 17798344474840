import { IAuthResponse } from 'api/digifi/auth/AuthTypes';

import BaseApi, { FetchMethodType } from './BaseApi';

interface ISearchAccountsResponse {
  isAccountExists: boolean;
}

export interface ISearchIntermediariesResponse {
  isIntermediaryExists: boolean;
  intermediaryId: string | null;
}

interface ISignUpProcessApi {
  searchAccounts: (phone: string) => Promise<ISearchAccountsResponse>;
  searchIntermediaries: (phone: string) => Promise<ISearchIntermediariesResponse>;
  createAccount: (password: string, intermediaryId: string, phone: string | null) => Promise<IAuthResponse>;
  searchAccountsByEmail: (email: string) => Promise<ISearchAccountsResponse>;
  searchIntermediariesByEmail: (email: string) => Promise<ISearchIntermediariesResponse>;
}

export default class SignUpProcessApi extends BaseApi implements ISignUpProcessApi {
  public async createAccount(password: string, intermediaryId: string, phone: string | null): Promise<IAuthResponse> {
    const response = await this.fetch<IAuthResponse>('/sign-up-process/create-account', {
      method: FetchMethodType.POST,
      body: {
        password,
        intermediaryId,
        phone
      },
    });

    return response;
  }

  public async searchIntermediaries(phone: string): Promise<ISearchIntermediariesResponse> {
    const response = await this.fetch<ISearchIntermediariesResponse>('/sign-up-process/search-intermediaries', {
      method: FetchMethodType.POST,
      body: {
        phoneNumber: phone,
      },
    });

    return response;
  }

  public async searchAccounts(phone: string): Promise<ISearchAccountsResponse> {
    const response = await this.fetch<ISearchAccountsResponse>('/sign-up-process/search-accounts', {
      method: FetchMethodType.POST,
      body: {
        phoneNumber: phone,
      },
    });

    return response;
  }

  public async searchAccountsByEmail(email: string): Promise<ISearchAccountsResponse> {
    const response = await this.fetch<ISearchAccountsResponse>('/sign-up-process/search-accounts-by-email', {
      method: FetchMethodType.POST,
      body: {
        email,
      },
    });

    return response;
  }

  public async searchIntermediariesByEmail(email: string): Promise<ISearchIntermediariesResponse> {
    const response = await this.fetch<ISearchIntermediariesResponse>('/sign-up-process/search-intermediaries-by-email', {
      method: FetchMethodType.POST,
      body: {
        email,
      },
    });

    return response;
  }
}
