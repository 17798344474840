import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import AppRoutes from 'routes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from 'App';

import PortalContextProvider from 'providers/PortalContext';

import store, { history } from './store';
import { EnvironmentType } from './enums/EnvironmentType';

import './index.scss';

const environment = process.env.REACT_APP_ENVIRONMENT;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  enabled: environment === EnvironmentType.Production,
});

ReactDOM.render(
  <Provider store={store}>
    <PortalContextProvider>
      <Router history={history}>
        <App>
          <AppRoutes />
        </App>
      </Router>
    </PortalContextProvider>
  </Provider>,
  document.getElementById('root'),
);
