import React from 'react';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';
import { ButtonType } from 'components/Button/Button';

import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';

import styles from './SignInProcessStep.module.scss';


const AccountUnsuccessfulStep = ({ changeStep, accountBlocked, noCompanyInfo }: ISignInStepProps) => {
  let subTitle = PageSubtitles.AccountUnsuccess;
  if(accountBlocked){
    subTitle = PageSubtitles.BlockedAccount;
  }
  if(noCompanyInfo){
    subTitle = PageSubtitles.NoCompanyInfo;
  }
  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.Sorry} subTitle={subTitle} />
      <div className={styles.contentWrapper}>
        <Button
          type={ButtonType.Primary}
          onClick={() => {
            if(accountBlocked){
              changeStep(SignInProcessSteps.OptionsStep);
            } else{
              changeStep(SignInProcessSteps.OptionsStep);
            }
          }}
        >
          Sign In
        </Button>
      </div>

    </div>
  );
};

export default AccountUnsuccessfulStep;
