export enum PrequalificationInputLabel {
  LoanPurpose = 'Loan Purpose',
  PurchasePriceOrEstimatedValue = 'Purchase Price / Estimated Value',
  LoanToValue = 'Loan to Value (LTV)',
  LoanAmount = 'Loan Amount',
  Reserves = 'Reserves',
  SelfEmployed = ' Self Employed',
  DebtToIncome = 'Debt to Income (DTI)',
  DebtServiceCoverageRatio = 'Debt Service Coverage Ratio (DSCR)',
  IncomeDocumentType = 'Income Document Type',
  InterestOnly = 'Interest Only',
  Citizenship = 'Citizenship',
  PrepaymentPenalty = 'Prepayment Penalty',
  EscrowWaiver = 'Escrow Waiver',

  CreditScore = 'Credit Score (FICO)',
  MortgagePaymentHistory = 'Mortgage Payment History',
  CreditEvents = 'Credit Events',

  PropertyType = 'Property Type',
  Occupancy = 'Occupancy',
  Vesting = 'Vesting',
  State = 'State',
  County = 'County',

  LenderPaidCompensation = 'Lender Paid Compensation',
  CompanyName = 'Company Name'
}
