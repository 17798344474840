import React from 'react';

import { ReactComponent as EHO } from 'images/equal-housing-opportunity.svg';

import styles from './Footer.module.scss';

const DISCLAIMER_TEXT =
  'ClearPoint Mortgage LLC (NMLS ID# 2442551). This material is only for informational purposes, for internal use and not intended for the general public. This is not an advertisement for a mortgage loan under federal and state lending regulations. This pricing tool is designed to provide preliminary pricing for loan scenarios and programs we offer. It does not constitute a lock, a credit decision, or guaranteed offer of a particular loan. All programs, terms and rates are subject to change without notice. The Annual Percentage Rate (APR) is based on a set of loan assumptions. These include a loan amount of $200,000, a 30 year fixed term and an 80% Loan to Value. The APR shown includes discount points, if any. One point is 1% of the mortgage loan amount (for example $2,000 on a $200,000 loan). The APR shown does not include information on closing costs or other fees, which will be provided after a loan application is received. Your actual rate and APR may differ from the scenario information presented. Scenarios are not guaranteed, and certain programs may not be available for your situation.';

const RetailDisclaimer = () => {
  return (
    <>
      {DISCLAIMER_TEXT}
      <br />
      <EHO className={styles.ehoLogo} />
    </>
  );
};

export default RetailDisclaimer;
