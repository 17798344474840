import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonType } from 'components/Button/Button';
import useSorting, { SortingType } from 'hooks/useSorting';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getPreQualificationData } from 'thunks';
import { PassedProgramsData, PreQualificationData } from 'api/PreQualificationApi';
import {
  DeclinedProgramsData,
  initialFiltersState,
  PreQualificationResultsFilters,
  setFilterOpen,
  setPreQualificationResultsFilters,
} from 'handlers/prequalificationResults';
import { RootState } from 'handlers';
import { resetPreQualificationFormData } from 'handlers/prequalificationForm';
import {
  setCurrentProductId,
  setFilterAvailableValues,
  setPricesAndRatesFilter,
} from 'handlers/prequalificationResultDetails';
import { createDefaultSortingHandler } from 'utils/createDefaultSortingHandler';
import { createCommissionSortingHandler } from 'utils/createCommissionSortingHandler';
import { compareArrays } from 'utils/compareTwoArrays';
import { removeSessionStorageItem } from 'utils/removeSessionStorageItem';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';
import { DeclinedProductTableHeaderTitle } from 'enums/DeclinedProductTableHeaderTitle';
import { ReactComponent as GradientFilterIcon } from 'images/gradient-filter-icon.svg';

import Dropdown from 'components/Dropdown';
import Popup from 'components/Popup';
import ProductsFilter from 'components/Filters/ProductsFilter';
import Button from 'components/Button';

import { settings } from 'settings';

import { usePortalContext } from 'providers/usePortalContext';

import PassedProductsTable from './PassedProductsTable';
import DeclinedProductsTable from './DeclinedProductsTable';

import styles from './PrequalificationResults.module.scss';

interface PrequalificationResultsProps {
  passedProgramsData: PassedProgramsData[];
  declinedProgramsData: DeclinedProgramsData[];
  isFilterOpen: boolean;
  filters: PreQualificationResultsFilters;
}

const PrequalificationResults: FC<PrequalificationResultsProps> = ({
  passedProgramsData,
  declinedProgramsData,
  isFilterOpen,
  filters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isLoading } = useSelector((state: RootState) => state.prequalificationResults);
  const { coercedApplication } = useSelector((state: RootState) => state.preQualificationForm);

  const { portalType } = usePortalContext();

  useEffect(() => {
    return () => {
      dispatch(setFilterOpen(false));
    };
  }, []);

  const [passedProgramSortingType, setPassedProgramSortingType] = useState<SortingType<PassedProductTableHeaderTitle>>({
    field: PassedProductTableHeaderTitle.LoanProduct,
    ascending: true,
  });

  const [declinedProgramSortingType, setDeclinedProgramSortingType] = useState<
    SortingType<DeclinedProductTableHeaderTitle>
  >({
    field: DeclinedProductTableHeaderTitle.LoanProduct,
    ascending: true,
  });

  const [passedProgramsChangeSorting] = useSorting(
    passedProgramSortingType.field,
    passedProgramSortingType.ascending,
    (field, ascending) => {
      setPassedProgramSortingType({
        field,
        ascending,
      });
    },
  );

  const [declinedProgramsChangeSorting] = useSorting(
    declinedProgramSortingType.field,
    declinedProgramSortingType.ascending,
    (field, ascending) => {
      setDeclinedProgramSortingType({
        field,
        ascending,
      });
    },
  );

  const passedProgramsSortedItems = useMemo(() => {
    return [...passedProgramsData].sort(
      passedProgramSortingType.field === PassedProductTableHeaderTitle.LPC ||
        passedProgramSortingType.field === PassedProductTableHeaderTitle.BPC ||
        passedProgramSortingType.field === PassedProductTableHeaderTitle.RatePoints ||
        passedProgramSortingType.field === PassedProductTableHeaderTitle.APR
        ? createCommissionSortingHandler<PassedProgramsData>(
            passedProgramSortingType.field,
            passedProgramSortingType.ascending,
          )
        : createDefaultSortingHandler<PassedProgramsData>(
            passedProgramSortingType.field,
            passedProgramSortingType.ascending,
          ),
    );
  }, [passedProgramsData, passedProgramSortingType]);

  const declinedProgramsSortedItems = useMemo(() => {
    return [...declinedProgramsData].sort(
      createDefaultSortingHandler<DeclinedProgramsData>(
        declinedProgramSortingType.field,
        declinedProgramSortingType.ascending,
      ),
    );
  }, [declinedProgramsData, declinedProgramSortingType]);

  const handleNewRequest = () => {
    removeSessionStorageItem(SessionStorageKeyName.PreQualificationForm);
    dispatch(resetPreQualificationFormData());

    history.push('/');
  };

  const handlePassedTableRowClick = (id: string) => {
    dispatch(setCurrentProductId(id));

    const currentProgram = passedProgramsData!.find((program) => program.id === id)!;

    const lpcRates = currentProgram.lpcData.map((item) => item.rate);
    const lpcPrices = currentProgram.lpcData.map((item) => item.price);
    const bpcRates = currentProgram.bpcData.map((item) => item.rate);
    const bpcPrices = currentProgram.bpcData.map((item) => item.price);
    const bpcPoints = currentProgram.bpcData.map((item) => item.point);

    const initialFilterState = {
      lpcRateRange: settings[portalType].getLpcRateRangeInitialValue(lpcRates),
      lpcPriceRange: settings[portalType].getLpcRateRangeInitialValue(lpcPrices),
      bpcRateRange: [Math.min(...bpcRates), Math.max(...bpcRates)],
      bpcPriceRange: [Math.min(...bpcPrices), Math.max(...bpcPrices)],
      bpcPointsRange: settings[portalType].getBpcPointsRangeInitialValue(bpcPoints),
    };

    dispatch(setFilterAvailableValues(initialFilterState));

    dispatch(setPricesAndRatesFilter(initialFilterState));

    history.push('/details');
  };

  const isFilterReset =
    compareArrays(filters.loanProductsFilter, initialFiltersState.loanProductsFilter) &&
    filters.lockTermFilter === initialFiltersState.lockTermFilter;

  const handleReset = async () => {
    const dataForGettingPrequalificationResult: PreQualificationData = {
      application: {
        ...coercedApplication!,
      },
      loanProductsFilter: initialFiltersState.loanProductsFilter,
      lockTermFilter: initialFiltersState.lockTermFilter,
      portalType,
    };

    await dispatchWithUnwrap(getPreQualificationData(dataForGettingPrequalificationResult));

    dispatch(
      setPreQualificationResultsFilters({
        loanProductsFilter: initialFiltersState.loanProductsFilter,
        lockTermFilter: initialFiltersState.lockTermFilter,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>Results</h1>
          <Button className={styles.newRequestButton} onClick={handleNewRequest}>
            New Request
          </Button>
        </div>
        <div className={styles.dropdown}>
          <Dropdown />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.sectionContainer}>
          <div className={styles.contentTitleContainer}>
            <h3 className={styles.contentTitle}>Available Products</h3>
            <div className={styles.buttonsContainer}>
              <Button
                type={ButtonType.Gradient}
                onClick={() => dispatch(setFilterOpen(true))}
                className={clsx(styles.filterButton)}
                active={!isFilterReset}
                icon={<GradientFilterIcon className={styles.gradientFilterIcon} />}
              >
                Filter
              </Button>
              {!isFilterReset && (
                <Button
                  type={ButtonType.GradientWithNoIcon}
                  onClick={handleReset}
                  isLoading={isLoading}
                  className={styles.resetButton}
                >
                  Reset
                </Button>
              )}
            </div>
          </div>
          <PassedProductsTable
            sortingType={passedProgramSortingType}
            changeSorting={passedProgramsChangeSorting}
            sortedItems={passedProgramsSortedItems}
            onTableRowClick={handlePassedTableRowClick}
            portalType={portalType}
          />
        </div>
        <h3 className={clsx(styles.contentTitle, styles.notAvailableProductsTitle)}>Not Available Products</h3>
        <DeclinedProductsTable
          sortedItems={declinedProgramsSortedItems}
          changeSorting={declinedProgramsChangeSorting}
          sortingType={declinedProgramSortingType}
          portalType={portalType}
        />
        <Button className={styles.newRequestButtonMobil} onClick={handleNewRequest}>
          New Request
        </Button>
      </div>
      {isFilterOpen && (
        <Popup disableBodyScroll>
          <ProductsFilter onPopupClose={() => dispatch(setFilterOpen(false))} portalType={portalType} />
        </Popup>
      )}
    </div>
  );
};

export default PrequalificationResults;
