export enum PrequalificationVariable {
  LoanPurpose = 'loan_purpose',
  PurchasePriceOrEstimatedValue = 'purchase_price_or_estimated_value',
  LoanToValue = 'ltv',
  LoanAmount = 'loan_amount',
  Reserves = 'reserves',
  DebtToIncome = 'dti',
  DebtServiceCoverageRatio = 'dscr',
  IncomeDocumentType = 'income_document_type',
  InterestOnly = 'interest_only',
  Citizenship = 'citizenship',
  PrepaymentPenalty = 'prepayment_penalty',
  EscrowWaiver = 'escrow_waiver',
  CreditScore = 'fico_score',
  MortgagePaymentHistory = 'mortgage_payment_history',
  CreditEvents = 'credit_events',
  PropertyType = 'property_type',
  Occupancy = 'occupancy_type',
  Vesting = 'vesting',
  State = 'state',
  County = 'county',
  SelfEmployed = 'self_employed',
  LenderPaidCompensation = 'lender_paid_compensation',
  CompanyName='company_name'
}
