import React, { forwardRef, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from 'images/close remove x.svg';
import styles from './ActionPopUp.module.scss';



interface IActionPopUpProps {
  title?: string;
  onClose?: () => void;
  children: ReactNode;
  isMobile?: boolean;
  className?: string;
  headerClassName?: string;
}

const ActionPopUp = forwardRef<HTMLDivElement, IActionPopUpProps>(
  ({
    onClose,
    children,
    title,
    isMobile,
    className,
    headerClassName,
  }, ref: Ref<HTMLDivElement>) => {
    return (
      <div className={clsx(styles.actionsPopUp, className)} ref={ref}>
        {!isMobile && <div className={styles.actionsPopUp__body}>{children}</div>}
        <div className={clsx(styles.actionsPopUp__header, headerClassName)}>
          <p>{title || 'Actions'}</p>
          <button type="button" aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        {isMobile && <div className={styles.actionsPopUp__body}>{children}</div>}
      </div>
    );
  },
);

export default ActionPopUp;
