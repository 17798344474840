import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PortalType } from 'settings';
import { Redirect, useHistory } from 'react-router';
import { getIntermediaryData } from 'thunks';
import { getSignInProcessState } from 'handlers/selectors';
import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useLoadingState } from 'hooks/useLoadingState';
import { usePortalContext } from 'providers/usePortalContext';
import PageLoader from 'components/PageLoader';
import { setLenderPaidCompensationData } from 'handlers/prequalificationForm';
import { setCurrentStep } from 'handlers/signInProcessSlice';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import TokenUtils from 'utils/TokenUtils';


const RedirectHandler: FC = ({ children }) => {
  const [loadingState, setLoadingState] = useLoadingState();
  const { accountData } = useAppSelector(getSignInProcessState);
  const dispatch = useDispatch();
  const { portalType, setPortalType } = usePortalContext();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const history = useHistory();

  const getIntermediary = async () => {
    try {
      setLoadingState('loading');

      const { whiteLabel, lenderPaidCompensation } = await dispatchWithUnwrap(getIntermediaryData(accountData?.intermediaryId!));

      setPortalType(whiteLabel as PortalType);
      if(lenderPaidCompensation){
        dispatch(setLenderPaidCompensationData(lenderPaidCompensation.toString()));
      }

      setLoadingState('success');
    } catch (err) {
      dispatch(setCurrentStep(SignInProcessSteps.UnsuccessfulStep));
      TokenUtils.removeTokens();
      history.push('/sign-in');
      setLoadingState('error');
    }
  };

 
  useEffect(() => {
    if(!accountData?.isBlocked){
      getIntermediary();
    }
    return () => {setLoadingState('loading');};
  }, [accountData]);

  if (!portalType) {
    if (loadingState === 'idle' || loadingState === 'loading') {
      return <PageLoader />;
    }

    return <Redirect to="/sign-in" />;
  }

  if(portalType){
    if (loadingState === 'idle' || loadingState === 'loading') {
      return <PageLoader />;
    } 
  }
  return <>{children}</>;
};

export default RedirectHandler;
