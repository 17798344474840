import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IGetCurrentAccountResponse } from 'api/SignInProcessApi';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { getCurrentAccount, logout, verifyPhoneVerificationCode, verifyEmailVerificationCode, getIntermediaryData } from 'thunks';

export type AccountDataType = Pick<
  IGetCurrentAccountResponse,
  'intermediaryId' | 'isMfaComplete' | 'isPhoneVerified' | 'isBlocked' | 'isEmailVerified'
>;
export interface CompanyData {
  companyId: string;
  companyName: string;
}

export interface ISignInProcessState {
  phone: string | null;
  email: string;
  accountData: AccountDataType | null;
  accountExists: boolean;
  phoneVerified: boolean;
  emailVerified: boolean;
  intermediaryId: string | null;
  currentStep: SignInProcessSteps;
  needVerification: boolean;
  needPhoneVerification: boolean;
  companiesData: Array<CompanyData> | null;
  isAccountExecutive: boolean;
  noCompanyInfo: boolean;
}

const initialState: ISignInProcessState = {
  phone: null,
  email: '',
  accountData: null,
  accountExists: false,
  phoneVerified: false,
  emailVerified: false,
  intermediaryId: null,
  needVerification: false,
  needPhoneVerification: false,
  currentStep: SignInProcessSteps.OptionsStep,
  companiesData: null,
  isAccountExecutive: false,
  noCompanyInfo: false,
};

const signInProcessSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPhone: (state, action: PayloadAction<{ phone: string | null }>) => {
      state.phone = action.payload.phone;
    },
    setEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.email = action.payload.email;
    },
    setAccountExists: (state, action: PayloadAction<{ accountExists: boolean }>) => {
      state.accountExists = action.payload.accountExists;
    },
    setEmailVerified: (state, action: PayloadAction<{ emailVerified: boolean }>) => {
      state.emailVerified = action.payload.emailVerified;
    },
    setIntermediaryId: (state, action: PayloadAction<{ intermediaryId: string | null }>) => {
      state.intermediaryId = action.payload.intermediaryId;
    },
    setCurrentStep:  (state, action: PayloadAction<SignInProcessSteps>) => {
      state.currentStep = action.payload;
    },
    setNeedVerification:  (state, action: PayloadAction<{ needVerification: boolean }>) => {
      state.needVerification = action.payload.needVerification;
    },
    setNeedPhoneVerification:  (state, action: PayloadAction<{ needPhoneVerification: boolean }>) => {
      state.needPhoneVerification = action.payload.needPhoneVerification;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentAccount.fulfilled, (state, { payload: { phone, email, ...rest } }) => {
        state.phone = phone;
        state.email = email;
        state.accountData = rest;
        state.accountExists = true;
        state.emailVerified = rest.isEmailVerified;
        state.phoneVerified = rest.isPhoneVerified;
        state.needVerification = rest && !rest.isEmailVerified;
        state.needPhoneVerification = rest && !!phone && !rest.isPhoneVerified;
      })
      .addCase(verifyPhoneVerificationCode.fulfilled, (state, { payload }) => {
        state.phoneVerified = true;
      })
      .addCase(verifyEmailVerificationCode.fulfilled, (state, { payload }) => {
        state.emailVerified = true;
      })
      .addCase(getIntermediaryData.fulfilled, (state, { payload: { id, companiesData, ...rest } }) => {
        state.intermediaryId =  id;   
        state.companiesData = companiesData;
        state.isAccountExecutive = !!companiesData;
        state.noCompanyInfo = false;  
       })
       .addCase(getIntermediaryData.rejected, (state, { payload }) => {
        state.noCompanyInfo = true;   
       })
      .addCase(logout.fulfilled, () => initialState);
  },
});

export const { setPhone, setEmail, setAccountExists, setEmailVerified, setIntermediaryId, setCurrentStep, setNeedVerification, setNeedPhoneVerification } = signInProcessSlice.actions;
export default signInProcessSlice.reducer;
