import { CompanyData } from 'handlers/signInProcessSlice';
import BaseApi, { FetchMethodType } from './BaseApi';

type VariableValue = number | string | boolean | null | undefined;

interface Intermediary {
  id: string;
  variables: Record<string, VariableValue>;
  whiteLabel: string;
  lenderPaidCompensation: number | null | undefined;
  companiesData: Array<CompanyData> | null;
  isAccountExecutive: boolean;
}
interface ICheckPhoneResponse {
  isPhoneMatch: boolean;
}

interface ILPCResponse {
  lenderPaidCompensation: string;
}

export interface IntermediaryApi {
  getIntermediaryData: (id: string) => Promise<Intermediary>;
  checkPhoneMatches: (phone: string, intermediaryId: string | null) => Promise<ICheckPhoneResponse>;
  getIntermediaryFullData: (id: string) => Promise<Intermediary>;
  getLPCForCompany: (companyId: string ) => Promise<ILPCResponse>;
}

export default class IntermediaryRestApi extends BaseApi implements IntermediaryApi {
  public async getIntermediaryData(id: string): Promise<Intermediary> {
    const data = await this.fetch<Intermediary>(`/intermediaries/${id}`);

    return data;
  }

  public async checkPhoneMatches(phone: string, intermediaryId: string | null): Promise<ICheckPhoneResponse> {
    const response = await this.fetch<ICheckPhoneResponse>('/intermediaries/check-phone-matches', {
      method: FetchMethodType.POST,
      body: {
        phoneNumber: phone,
        intermediaryId
      },
    });
    return response;
  }

  public async getIntermediaryFullData(id: string): Promise<Intermediary> {
    const data = await this.fetch<Intermediary>(`/intermediaries/get-info/${id}`);

    return data;
  }

  public async getLPCForCompany(companyId: string): Promise<ILPCResponse> {
    const data = await this.fetch<ILPCResponse>(`/intermediaries/get-lpc-for-company/${companyId}`);
    return data;
  }
}
