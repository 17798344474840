import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import TokenUtils from 'utils/TokenUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createLogoutThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.SignOut, async (_, thunkApi) => {
    try {
      await api.logout();

      TokenUtils.removeTokens();
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
