import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import SignInRightBar from 'components/SignInRightBar';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppSelector } from 'hooks/reduxHooks';
import { resetChangePhoneProcess, setChangePhoneStep, setNewPhone } from 'handlers/changePhoneProcessSlice';
import Header from 'components/Header';
import { getCurrentAccount } from 'thunks';
import { getChangePhoneProcessState } from 'handlers/selectors';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { usePortalContext } from 'providers/usePortalContext';
import { PortalType } from 'settings';
import { ChangePhoneProcessSteps } from './constants';
import NewPhoneNumberStep from './steps/NewPhoneNumberStep';
import CompleteStep from './steps/CompleteStep';
import ConfirmPasswordStep from './steps/ConfirmPasswordStep';

import styles from './ChangePhonePage.module.scss';
import ConfirmCodeStep from './steps/ConfirmCodeStep';


export interface IChangePhonePageProps {
  nextStep: (step: ChangePhoneProcessSteps) => void;
  dismissStep?: (isFinal: boolean) => void;
}

const ChangePhonePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { portalType, setPortalType } = usePortalContext();
  const [initialPortalType, setInitialPortalType] = useState(portalType);
  const { changePhoneStep, isLoading } = useAppSelector(getChangePhoneProcessState);
  const nextStep = (step: ChangePhoneProcessSteps) => {
    dispatch(setChangePhoneStep(step));
  };
 
  const dismissStep = async (isFinal: boolean) => {
    setPortalType(initialPortalType);
    dispatch(resetChangePhoneProcess());
    if(isFinal){
     // await dispatchWithUnwrap(getCurrentAccount());
    }
    history.goBack();
  };
  useEffect(() => {
    setInitialPortalType(portalType);
    setPortalType(PortalType.Wholesale);
  }, []);

  const renderStep = () => {
    switch (changePhoneStep) {
      case ChangePhoneProcessSteps.NewPhoneStep:
        return <NewPhoneNumberStep nextStep={nextStep}/>;
      case ChangePhoneProcessSteps.ConfirmPasswordStep:
        return <ConfirmPasswordStep nextStep={nextStep}/>;
      case ChangePhoneProcessSteps.ConfirmCodeStep:
        return <ConfirmCodeStep nextStep={nextStep}/>;
      case ChangePhoneProcessSteps.CompleteStep:
        return <CompleteStep nextStep={nextStep} dismissStep={dismissStep}/>
      default:
        return null as never;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Header disposedInBlock />
          <div className={styles.form}>
          <div className={styles.formWrapper}>
            {renderStep()}
            {!isLoading && changePhoneStep !== ChangePhoneProcessSteps.CompleteStep 
            &&
            <div className={styles.buttonsContainer}>
              <Button
                  type={ButtonType.Secondary}
                  onClick={()=> dismissStep(false)}
                >
                  Discard
                </Button>
              </div>
           }
            </div>  
          </div>
        </div>
      </div>
      <SignInRightBar />
      <div className={styles.footer}>
        <p>{new Date().getFullYear()} © Logan Finance Corporation. All rights reserved.</p>
      </div>
    </div >
  );
};

export default ChangePhonePage;
