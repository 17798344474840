export enum PageErrors {
    CreateAccount = 'We are sorry, but an account cannot be created for the entered email. Please check your email or contact your account executive.',
    AccountExists = 'An account with this email address already exists. Please Sign In.',
    MoreThanOneAccountExists = 'This phone is attached to more than one account. Please make sure the phone number you entered is correct or contact your account executive if you continue to see the issue.',
    SignInPhoneCode = 'Please make sure the phone number and code you provided are correct and try again.',
    VerifyEmailCode = 'Please make sure the code you provided is correct and try again or get a new code by clicking Resend Code button.',
    PasswordSignIn = 'Invalid email or password. Please try again.',
    TechnicalIssue = 'We are sorry, your account cannot be created at this time due to the technical issue. Please contact your account executive.',
    AccountWithPhoneExists = 'The phone number you entered cannot be added to your account. Please provide another phone number.',
    NoCompanyInfoShort = 'The system did not find corresponding information about this company',
    PasswordChangePhone = 'Invalid password. Please try again.',
    ChangePhoneCodeIssue = 'We are sorry, code can not be send to the provided phone number at this time due to the technical issue. Please contact your account executive.'
}
