import PreQualificationRestApi from 'api/PreQualificationApi';
import { env } from 'env';

import IntermediaryRestApi from 'api/IntermediaryApi';
import SignInProcessApi from 'api/SignInProcessApi';
import VerificationProcessApi from 'api/VerificationProcessApi';
import SignUpProcessApi from 'api/SignUpProcessApi';

import { createGetPreQualificationDataThunk } from './createPreQualificationThunk';
import { checkPhoneMatchesThunk, createGetIntermediaryDataThunk, createGetLPCForCompanyThunk } from './createGetIntermediaryDataThunk';
import { createSendVerificationCodeThunk } from './createSendVerificationCodeThunk';
import { createVerifySignInCodeThunk } from './createVerifySignInCodeThunk';
import { createLogoutThunk } from './createLogoutThunk';
import { createSendPhoneVerificationCodeThunk } from './createSendPhoneVerificationCodeThunk';
import { createSendEmailVerificationCodeThunk } from './createSendEmailVerificationCodeThunk';
import { createVerifyEmailVerificationCode } from './createVerifyEmailVerificationCode';
import { createVerifyPhoneVerificationCodeThunk } from './createVerifyPhoneVerificationCodeThunk';
import { createVerifySessionThunk } from './createVerifySessionThunk';
import { createGetCurrentAccountThunk } from './createGetCurrentAccountThunk';
import { createSearchAccountsByEmailThunk, createSearchAccountsThunk } from './createSearchAccountsThunk';
import { createSearchIntermediariesByEmailThunk, createSearchIntermediariesThunk } from './createSearchIntermediariesThunk';
import { createCreateAccountThunk } from './createCreateAccountThunk';
import { createCheckAvailabilityThunk} from './createCheckAvailabilityThunk';
import { createVerifySignInPasswordThunk } from './createVerifySignInPasswordThunk';
import { createResetPasswordEmailThunk } from './createResetPasswordEmailThunk';
import { createNewPasswordThunk } from './createNewPasswordThunk';
import { createGetPasswordValidationTokenThunk } from './createGetPasswordValidationToken'
import { createSendUpdatePhoneCodeThunk } from './createSendUpdatePhoneCodeThunk';
import { createVerifyUpdatePhoneCodeThunk } from './createVerifyUpdatePhoneCodeThunk';

const preQualificationApi = new PreQualificationRestApi(env.REACT_APP_API_URL);
const intermediaryApi = new IntermediaryRestApi(env.REACT_APP_API_URL);
const signInProcessApi = new SignInProcessApi(env.REACT_APP_API_URL);
const verificationProcessApi = new VerificationProcessApi(env.REACT_APP_API_URL);
const signUpProcessApi = new SignUpProcessApi(env.REACT_APP_API_URL);

export const getPreQualificationData = createGetPreQualificationDataThunk(preQualificationApi);
export const checkPricingAvailabilityData = createCheckAvailabilityThunk(preQualificationApi);

export const getIntermediaryData = createGetIntermediaryDataThunk(intermediaryApi);
export const checkPhoneMatches = checkPhoneMatchesThunk(intermediaryApi);
export const getLPCForCompany = createGetLPCForCompanyThunk(intermediaryApi);

// SignInProcess
export const sendVerificationCode = createSendVerificationCodeThunk(signInProcessApi);
export const verifyCode = createVerifySignInCodeThunk(signInProcessApi);
export const verifyPassword= createVerifySignInPasswordThunk(signInProcessApi);  
export const verifySession = createVerifySessionThunk(signInProcessApi);
export const logout = createLogoutThunk(signInProcessApi);
export const getCurrentAccount = createGetCurrentAccountThunk(signInProcessApi);
export const resetPasswordEmail = createResetPasswordEmailThunk(signInProcessApi);
export const createNewPassword = createNewPasswordThunk(signInProcessApi);
export const createPasswordValidationToken = createGetPasswordValidationTokenThunk(signInProcessApi);
export const sendUpdatePhoneCode = createSendUpdatePhoneCodeThunk(signInProcessApi);
export const verifyUpdatePhoneCode = createVerifyUpdatePhoneCodeThunk(signInProcessApi);

// VerificationProcess
export const sendPhoneVerificationCode = createSendPhoneVerificationCodeThunk(verificationProcessApi);
export const verifyPhoneVerificationCode = createVerifyPhoneVerificationCodeThunk(verificationProcessApi);
export const sendEmailVerificationCode =
  createSendEmailVerificationCodeThunk(verificationProcessApi);
export const verifyEmailVerificationCode =
  createVerifyEmailVerificationCode(verificationProcessApi);

// SignUpProcess
export const searchAccounts = createSearchAccountsThunk(signUpProcessApi);
export const searchIntermediaries = createSearchIntermediariesThunk(signUpProcessApi);
export const searchAccountsByEmail = createSearchAccountsByEmailThunk(signUpProcessApi);
export const searchIntermediariesByEmail = createSearchIntermediariesByEmailThunk(signUpProcessApi);
export const createAccount = createCreateAccountThunk(signUpProcessApi);


