import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { useAppSelector } from 'hooks/reduxHooks';
import { useLoadingState } from 'hooks/useLoadingState';

import { getSignInProcessState } from 'handlers/selectors';
import { setEmail } from 'handlers/signInProcessSlice';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import Input from 'components/Input';
import InputError from 'components/InputError';
import { ButtonType } from 'components/Button/Button';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';

import { ReactComponent as LeftArrow } from 'images/arrow-left.svg';

import { createFormikHelpers } from 'utils/formHelpers';

import RequestError from 'errors/RequestError';

import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';

import {
  IEmailFormValues,
  EmailFormFields,
  emailFormValidationSchema,
} from 'validationSchemas/emailStepValidation';

import styles from './SignInProcessStep.module.scss';

const ResetPasswordStep = ({ changeStep }: ISignInStepProps) => {
  const { email } = useAppSelector(getSignInProcessState);
  const [emailError, setEmailError] = useState<string>('');
  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useLoadingState();

  const handleSubmit = async (values: IEmailFormValues) => {
    try {
      setLoadingState('loading');
      dispatch(setEmail({ email: values[EmailFormFields.Email] }));
      setLoadingState('success');
      return changeStep(SignInProcessSteps.ResetPasswordInfoStep);
    } catch (err) {
      const error = err as RequestError;

      if (error.message) {
        //  setEmailError(CREATE_ACCOUNT_ERROR);
      }
      setLoadingState('error');
    }
  };

  const isLoading = loadingState === 'loading';

  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.ResetPassword} subTitle={PageSubtitles.ResetPassword} />
      <Formik
        initialValues={{ email }}
        validationSchema={emailFormValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >

        {({ touched, errors, submitForm, isValid, setFieldValue, setTouched, values }) => {
          const handleContinue = async () => {
            await submitForm();
          };

          const { handleInputChange, handleTouched, getInputErrorMessage, handleResetExternalError } = createFormikHelpers<
            EmailFormFields,
            IEmailFormValues
          >(setFieldValue, setTouched, touched, errors, { email: emailError },
            () => setEmailError(''));

          return (
            <section className={styles.contentWrapper}>
              <label className={getInputErrorMessage(EmailFormFields.Email) && styles.inputError}>
                Email
                <Input
                  value={values[EmailFormFields.Email]}
                  onChange={(e) => {
                    handleInputChange(EmailFormFields.Email)(e.target.value);
                  }}
                  onBlur={handleTouched(EmailFormFields.Email)}
                  onFocus={handleResetExternalError(EmailFormFields.Email)}
                  required
                  placeholder='email@domain.com'
                />
                {getInputErrorMessage(EmailFormFields.Email) && (
                  <InputError errorMessage={getInputErrorMessage(EmailFormFields.Email)!} />
                )}
              </label>
              <div className={styles.buttonsContainer}>
                <Button type={ButtonType.Secondary} className={styles.backBtn} onClick={() => changeStep(SignInProcessSteps.SignInByEmailStep)}>
                  <LeftArrow />
                </Button>
                <Button type={ButtonType.Primary} onClick={handleContinue} isLoading={isLoading} disabled={!isValid}>
                  Reset Password
                </Button>
              </div>

            </section>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResetPasswordStep;
