import React, { useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { nanoid } from 'nanoid';
import { unFormatBorrowerPhone } from 'utils/validation';

import Input from 'components/Input';

type PhoneNumberInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange' | 'ref' | 'defaultValue' | 'type' | 'name' | 'id' | 'autoComplete'
> & {
  value: string;
  errormessage?: string;
  onChange: (value: string) => void;
};

const PHONE_FORMAT = '(###) ###-####';
const PHONE_MASK = 'X';

const PhoneNumberInput = ({ value, onChange, onBlur, onFocus, ...props }: PhoneNumberInputProps) => {
  const [displayedValue, setDisplayedValue] = useState<string>(value.replace('+1', ''));

  const handleChange = (values: { value: string }) => {
    setDisplayedValue(values.value);
    onChange(unFormatBorrowerPhone(values.value));
  };

  const uniqueId = useRef(nanoid());

  return (
    <NumberFormat
      format={PHONE_FORMAT}
      mask={PHONE_MASK}
      placeholder="(XXX) XXX-XXXX"
      value={displayedValue}
      onValueChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      customInput={Input}
      name={uniqueId.current}
      id={uniqueId.current}
      autoComplete={uniqueId.current}
      {...props}
    />
  );
};

export default PhoneNumberInput;
