import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TokenUtils from 'utils/TokenUtils';

export const createVerifySessionThunk = (api: SignInProcessApi) =>
  createAsyncThunk(
    SignInProcessActionTypes.VerifySession,
    async ({ intermediaryId }: { intermediaryId: string }) => {
      try {
        await api.verifySession(intermediaryId);

        return { isSessionVerified: true };
      } catch (error) {
        TokenUtils.removeTokens();
        return { isSessionVerified: false };
      }
    },
  );
