import { PortalType } from 'settings';
import { CoercedApplication } from 'handlers/prequalificationForm';
import { ProductVariable } from 'enums/ProductVariable';
import { StrategyId } from 'enums/StrategyId';
import { ProductTerm } from 'enums/ProductTerm';

import BaseApi, { FetchMethodType } from './BaseApi';

export interface PreQualificationData {
  application: CoercedApplication;
  loanProductsFilter: StrategyId[];
  lockTermFilter: string;
  portalType: PortalType;
  intermediaryPriceAdjustment?: number;
}

export interface PricesToRates {
  price: number;
  rate: number;
  id: string;
  defaultBasePrice: number;
  cap: number;
  point: number;
}

export interface Apr {
  value: number;
  bpcId: string;
}

export interface PriceAdjustment {
  name: string;
  value: number;
}

export interface ProductDisclaimers {
  disclaimer1: string | null;
  disclaimer2: string | null;
  disclaimer3: string | null;
}

export interface PassedProgramsData {
  id: string;
  [ProductVariable.LoanProduct]: string;
  [ProductVariable.ProductTerms]: ProductTerm;
  [ProductVariable.LockTerm]: string;
  [ProductVariable.LpcBestOption]: PricesToRates;
  [ProductVariable.BpcBestOption]: PricesToRates;
  lpcData: PricesToRates[];
  bpcData: PricesToRates[];
  priceAdjustmentsList: PriceAdjustment[];
  lenderPaidCompensation: number;
  aprData: Apr[];
  aprBestOption: Apr;
  productDisclaimers: ProductDisclaimers;
}

interface DeclinedProgramsData {
  [ProductVariable.LoanProduct]: string;
  [ProductVariable.DeclineReasons]: string[];
}

export interface PrequalificationResult {
  passedPrograms: PassedProgramsData[];
  declinedPrograms: DeclinedProgramsData[];
  decisionId: string;
}

export interface PricingAvailabilityResult{
  passed: boolean;
  pricingAvailable: boolean;
  intermediaryPriceAdjustment: number | null;
}

export interface PreQualificationApi {
  getPreQualificationResult(preQualificationData: PreQualificationData): Promise<PrequalificationResult>;
  checkPricingAvailability(intermediaryId: string, companyId?: string | null): Promise<PricingAvailabilityResult>;
}

export default class PreQualificationRestApi extends BaseApi implements PreQualificationApi {
  public async getPreQualificationResult(preQualificationData: PreQualificationData) {
    const data = await this.fetch<PrequalificationResult>('/prequalification', {
      method: FetchMethodType.POST,
      body: {
        preQualificationData,
      },
    });

    return data;
  }

  public async checkPricingAvailability(intermediaryId: string,  companyId?: string | null) {
      const data = await this.fetch<PricingAvailabilityResult>('/prequalification/check-pricing-availability', {
        method: FetchMethodType.POST,
        body: {
          intermediaryId,
          companyId
        },
      });
      return data;
    
  }
}
