import { combineReducers } from '@reduxjs/toolkit';

import preQualificationFormReducer from './prequalificationForm';
import prequalificationResultsReducer from './prequalificationResults';
import prequalificationResultDetailsReducer from './prequalificationResultDetails';
import isStylesLoadedReducer from './isStylesLoaded';
import signInProcessSlice from './signInProcessSlice';
import verificationProcessSlice from './verificationProcessSlice';
import checkPricingAvailabilityResultReducer from './checkPricingAvailabilityResult';
import changePhoneProcessSlice from './changePhoneProcessSlice';


const rootReducer = combineReducers({
  preQualificationForm: preQualificationFormReducer,
  prequalificationResults: prequalificationResultsReducer,
  prequalificationResultDetails: prequalificationResultDetailsReducer,
  isStylesLoaded: isStylesLoadedReducer,
  signInProcess: signInProcessSlice,
  changePhoneProcess: changePhoneProcessSlice,
  verificationProcess: verificationProcessSlice,
  pricingAvailability: checkPricingAvailabilityResultReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
