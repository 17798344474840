import * as Yup from 'yup';

import { isValidPhoneNumber } from 'react-phone-number-input';

export enum PhoneNumberFormFields {
  Phone = 'phone',
}

export interface IPhoneNumberFormValues {
  [PhoneNumberFormFields.Phone]: string;
}

export const phoneNumberFormValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Phone is required')
    .test('isValidPhoneNumber', 'Please enter a valid phone number.', (value) => {
      if (!value) {
        return false;
      }
      return isValidPhoneNumber(value, {
        defaultCountry: 'US',
      });
    }),
});
