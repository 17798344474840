import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createNewPasswordThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.CreateNewPassword,
    async ({ password, resetPasswordToken }: { password: string, resetPasswordToken: string }, thunkApi) => {
    try {
      await api.createNewPassword(password, resetPasswordToken);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
