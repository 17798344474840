import * as Yup from 'yup';

export enum EmailSignInFormFields {
  Email = 'email',
  Password = 'password',
}
export interface IEmailSignInFormValues {
  [EmailSignInFormFields.Email]: string;
  [EmailSignInFormFields.Password]: string;
}

export const emailSignInFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address.').required('Email is required'),
  password: Yup.string().required('Password is required')
});
