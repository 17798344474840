import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Header from 'components/Header';
import { getSignInProcessState } from 'handlers/selectors';
import { useAppSelector } from 'hooks/reduxHooks';
import { ChangePhoneProcessSteps } from 'pages/ChangePhonePage/constants';
import { setCurrentStep } from 'handlers/signInProcessSlice';

import PhoneNumberStep from './steps/PhoneNumberStep';

import {SignInProcessSteps } from './constants';

import VerificationCodeStep from './steps/VerificationCodeStep';
import AccountUnsuccessfulStep from './steps/AccountUnsuccessfulStep';
import SignInOptionsStep from './steps/SignInOptionsStep';
import ResetPasswordSuccessfulStep from './steps/ResetPasswordSuccessfulStep';
import CreateAccountEmailStep from './steps/CreateAccountEmailStep';
import CreateAccountPhoneStep from './steps/CreateAccountPhoneStep';
import CreatePasswordStep from './steps/CreatePasswordStep';
import EmailSignInStep from './steps/EmailSignInStep';
import ResetPasswordStep from './steps/ResetPasswordStep';
import ResetPasswordInfoStep from './steps/ResetPasswordInfoStep';

import styles from './SignInLeftBar.module.scss';


export interface ISignInStepProps {
  changeStep: (step: SignInProcessSteps) => void;
  accountBlocked?: boolean;
  resetPassword?: boolean;
  noCompanyInfo?: boolean;
}

const SignInLeftBar = () => {
  const dispatch = useDispatch();
  const { currentStep } = useAppSelector(getSignInProcessState);
  const { accountData, emailVerified, noCompanyInfo, needPhoneVerification } = useAppSelector(getSignInProcessState);
  const changeStep = (step: SignInProcessSteps) => {
    dispatch(setCurrentStep(step));
  } ;

  useEffect(() => {
    if(noCompanyInfo){
      changeStep(SignInProcessSteps.UnsuccessfulStep);
    }
  }, [noCompanyInfo]);
  
  useEffect(() => {
    if(accountData){

      if(accountData.isBlocked){
        changeStep(SignInProcessSteps.UnsuccessfulStep);
      }

      if(!emailVerified || needPhoneVerification){
        changeStep(SignInProcessSteps.VerificationCodeStep);     
      }
    }

  }, [accountData]);
  
  const renderStep = () => {
    switch (currentStep) {
      case SignInProcessSteps.OptionsStep:
        return <SignInOptionsStep changeStep={changeStep}/>;
      case SignInProcessSteps.SignInStep:
        return <PhoneNumberStep changeStep={changeStep}/>;
      case SignInProcessSteps.SignInByEmailStep:
        return <EmailSignInStep changeStep={changeStep}/>;
      case SignInProcessSteps.CreateAccountEmailStep:
        return <CreateAccountEmailStep changeStep={changeStep} />;
      case SignInProcessSteps.CreateAccountPhoneStep:
        return <CreateAccountPhoneStep changeStep={changeStep} />;
      case SignInProcessSteps.CreatePasswordStep:
          return <CreatePasswordStep changeStep={changeStep}/>;
      case SignInProcessSteps.VerificationCodeStep:
        return <VerificationCodeStep changeStep={changeStep} />;
      case SignInProcessSteps.UnsuccessfulStep:
          return <AccountUnsuccessfulStep changeStep={changeStep} accountBlocked={accountData?.isBlocked} noCompanyInfo={noCompanyInfo} />;
        case SignInProcessSteps.ResetPasswordStep:
          return <ResetPasswordStep changeStep={changeStep} />;
        case SignInProcessSteps.ResetPasswordInfoStep:
          return <ResetPasswordInfoStep changeStep={changeStep} />;
      case SignInProcessSteps.ResetPasswordSuccessfulStep:
          return <ResetPasswordSuccessfulStep changeStep={changeStep} />;
      default:
        return null as never;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Header disposedInBlock defaultLogo />
        <div className={styles.form}>{renderStep()}</div>
      </div>
    </div>
  );
};

export default SignInLeftBar;
