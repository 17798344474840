import React, { FC, forwardRef, Ref, useRef, useState } from 'react';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { ReactComponent as MyAccountIcon } from 'images/my-account.svg';
import styles from './MyAccount.module.scss';

interface MyAccountProps {
  forMobile?: boolean;
  clickEvent?: () => void;
}

const MyAccount = forwardRef<HTMLDivElement, MyAccountProps>(
  (
    { forMobile, clickEvent },
    ref: Ref<HTMLDivElement>,
  ) => {

    return (
        forMobile ?
          <div ref={ref}>
          <Button  type={ButtonType.Secondary} className={styles.logout} onClick={clickEvent}>
            <MyAccountIcon />
          </Button>
          </div>
          :
          <div ref={ref}>
          <Button type={ButtonType.Secondary} className={styles.logout} icon={<MyAccountIcon />} onClick={clickEvent}>
            My Account
          </Button>
          </div>
        
    
    );
  }
);

export default MyAccount;
