import { createSlice } from '@reduxjs/toolkit';
import { VerificationCodeStatus } from 'api/VerificationProcessApi';
import { sendPhoneVerificationCode } from 'thunks';

export interface IVerificationProcessState {
  phoneVerificationData: {
    sendingAttemptsLeft: number;
    status: VerificationCodeStatus;
  } | null;
}

const initialState: IVerificationProcessState = {
  phoneVerificationData: null,
};

const verificationProcessSlice = createSlice({
  name: 'verificationProcess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendPhoneVerificationCode.fulfilled, (state, { payload }) => {
        state.phoneVerificationData = payload;
      });
  },
});

export default verificationProcessSlice.reducer;
