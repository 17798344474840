import  { RootState }  from 'handlers';

export const getSignInProcessState = (state: RootState) => state.signInProcess;

export const getChangePhoneProcessState = (state: RootState) => state.changePhoneProcess;

export const getPhoneVerificationData = (state: RootState) =>
  state.verificationProcess.phoneVerificationData;

export const getPricingAvailabilityState = (state: RootState) => state.pricingAvailability;

export const prequalDataState = (state: RootState) => state.preQualificationForm;
