export enum PageSubtitles {
   CreateAccountEmail = 'Please enter your email to create an account and get access to the portal.',
   CreateAccountPhone = 'Please enter your cell phone number to create an account and get access to the portal.',
   CreateAccountSkip = 'You can skip this step and add a phone number later.',
   CreateAccountPassword = 'Please enter your password to create an account and get access to the portal.',
   SignIn = 'Sign in to your account.',
   ResetPassword = 'Please enter your email to reset your password and get access to the portal.',
   ResetPasswordSuccess = 'Your password is successfully updated. Please Sign In.',
   Sorry = 'Unfortunately, price information is not available at the moment. Please try later.',
   NewPassword= 'Please enter your new password to get access to the portal.',
   AccountUnsuccess = 'This phone number is attached to more than one account. Please make sure the phone number you entered is correct or contact your account executive if you continue to see the issue.',
   BlockedAccount = 'Your account has been disabled. Please contact your account executive.',
   NoCompanyInfo = 'Unfortunately, the system did not find corresponding information about this company. Please contact your account executive if you continue to see the issue.',
   ChangePhone = 'Please enter a cell phone number you want to connect to the account.',
   ChangePhoneSuccess = 'Your phone number is successfully updated.',

}
