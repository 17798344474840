import { SignInProcessActionTypes } from 'enums/SignInProcessActionTypes';
import SignInProcessApi from 'api/SignInProcessApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createSendVerificationCodeThunk = (api: SignInProcessApi) =>
  createAsyncThunk(SignInProcessActionTypes.SendVerificationCode, async (phoneNumber: string, thunkApi) => {
    try {
      await api.sendVerificationCode(phoneNumber);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
