export enum PrequalificationInputName {
  LoanPurpose = 'loanPurpose',
  PurchasePriceOrEstimatedValue = 'purchasePriceOrEstimatedValue',
  LoanToValue = 'loanToValue',
  LoanAmount = 'loanAmount',
  Reserves = 'reserves',
  SelfEmployed = 'selfEmployed',
  DebtToIncome = 'debtToIncome',
  DebtServiceCoverageRatio = 'debtServiceCoverageRatio',
  IncomeDocumentType = 'incomeDocumentType',
  InterestOnly = 'interestOnly',
  Citizenship = 'citizenship',
  PrepaymentPenalty = 'prepaymentPenalty',
  EscrowWaiver = 'escrowWaiver',

  CreditScore = 'creditScore',
  MortgagePaymentHistory = 'mortgagePaymentHistory',
  CreditEvents = 'creditEvents',

  PropertyType = 'propertyType',
  Occupancy = 'occupancy',
  Vesting = 'vesting',
  State = 'state',
  County = 'county',

  LenderPaidCompensation = 'lenderPaidCompensation',
  CompanyName = 'companyName'
}
