import React from 'react';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import { ButtonType } from 'components/Button/Button';
import { SignInProcessSteps } from 'components/SignInLeftBar/constants';
import { ISignInStepProps } from 'components/SignInLeftBar/SignInLeftBar';

import { PageTitles } from 'enums/PageTitles';
import { PageSubtitles } from 'enums/PageSubtitles';

import styles from './SignInProcessStep.module.scss';

const ResetPasswordSuccessfulStep = ({ changeStep }: ISignInStepProps) => {
  return (
    <div className={styles.formWrapper}>
      <FormTitle title={PageTitles.ResetPasswordSuccess} subTitle={PageSubtitles.ResetPasswordSuccess} />
      <div className={styles.contentWrapper}>
        <Button
          type={ButtonType.Primary}
          onClick={() =>
            changeStep(SignInProcessSteps.OptionsStep)}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessfulStep;
