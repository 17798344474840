import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Home from 'pages/Home';
import NotFound from 'pages/NotFound';

import ResultDetails from 'pages/ResultDetails';
import Results from 'pages/Results';
import { RootState } from 'handlers';
import SignInPage from 'pages/SignInPage';
import SorryPage from 'pages/SorryPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ChangePhonePage from 'pages/ChangePhonePage';

import { setIsStylesLoaded } from 'handlers/isStylesLoaded';
import { usePortalContext } from 'providers/usePortalContext';
import useThemeByPortalType from 'hooks/useThemeByPortalType';

import RedirectHandler from './RedirectHandler';
import RequiredAuth from './RequiredAuth';


const ThemeHandler: FC = ({ children }) => {
  const { portalName } = usePortalContext();
  const dispatch = useDispatch();
  
  const { isStylesLoaded } = useSelector((state: RootState) => state);

  useThemeByPortalType(portalName).then(([loading]) => dispatch(setIsStylesLoaded(!loading)));

  return <>{isStylesLoaded && children}</>;
};

export default () => {
  return (
    <Route path="/">
      <Switch>
        <Route path="/sign-in" exact component={SignInPage} />
        <Route path="/reset-password" component={ResetPasswordPage}/>
        <RequiredAuth>
          <RedirectHandler>
            <ThemeHandler>
              <Route path="/" exact component={Home} />
              <Route path="/results" exact component={Results} />
              <Route path="/details" exact component={ResultDetails} />
              <Route path="/sorry" exact component={SorryPage}/>
              <Route path="/change-phone" exact component={ChangePhonePage}/>
            </ThemeHandler>
          </RedirectHandler>
        </RequiredAuth>
        <Route component={NotFound} />
      </Switch>
    </Route>
  );
};
