import { createAsyncThunk } from '@reduxjs/toolkit';
import SignUpProcessApi from 'api/SignUpProcessApi';
import { SignUpProcessActionTypes } from 'enums/SignUpProcessActionTypes';

export const createSearchIntermediariesThunk = (api: SignUpProcessApi) =>
  createAsyncThunk(SignUpProcessActionTypes.SearchIntermediaries, async (phoneNumber: string) => {
    const response = await api.searchIntermediaries(phoneNumber);
    return response;
  });


export const createSearchIntermediariesByEmailThunk = (api: SignUpProcessApi) =>
  createAsyncThunk(SignUpProcessActionTypes.SearchIntermediariesByEmail, async (email: string) => {
    const response = await api.searchIntermediariesByEmail(email);
    return response;
  });
