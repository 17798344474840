import React, { FC, createContext, useState } from 'react';
import { PortalName, PortalType } from 'settings';

export interface PortalParamsType {
  portalType: PortalType | null;
  portalName: PortalName | null;
  setPortalType: (portalType: PortalType) => void;
}

export const PortalContext = createContext<PortalParamsType>({
  portalType: null,
  portalName: null,
  setPortalType: () => {},
});

const getPortalNameByPortalType = (portalType: PortalType | null): PortalName => {
  switch (portalType) {
    case PortalType.Wholesale:
      return PortalName.Wholesale;
    case PortalType.Retail:
      return PortalName.Retail;
    default:
      return null as never;
  }
};

const PortalContextProvider: FC = ({ children }) => {
  const [portalType, setPortalType] = useState<PortalType | null>(null);

  return (
    <PortalContext.Provider
      value={{
        portalType,
        portalName: getPortalNameByPortalType(portalType),
        setPortalType,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};

export default PortalContextProvider;
