import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';

const createInputChangeHelper =
  <
    FormFieldNames extends string,
    FormValuesInterface extends Record<FormFieldNames, string | number | null | boolean>,
  >(
    setFunction: FormikHelpers<FormValuesInterface>['setFieldValue'],
  ) =>
  (inputName: FormFieldNames) =>
  (value: FormValuesInterface[FormFieldNames]) => {
    setFunction(inputName, value);
  };

const createInputTouchedHelper =
  <
    FormFieldNames extends string,
    FormValuesInterface extends Record<FormFieldNames, string | number | null | boolean>,
  >(
    setTouched: FormikHelpers<FormValuesInterface>['setTouched'],
    touchedFields: FormikTouched<FormValuesInterface>,
  ) =>
  (inputName: FormFieldNames) =>
  () => {
    setTouched({ ...touchedFields, [inputName]: true });
  };

const createInputErrorHelper =
  <
    FormFieldNames extends string,
    FormValuesInterface extends Record<FormFieldNames, string | number | null | boolean>,
  >(
    touchedFields: FormikTouched<FormValuesInterface>,
    errors: FormikErrors<FormValuesInterface>,
    externalErrors?: Partial<Record<FormFieldNames, string>>,
  ) =>
  (fieldName: FormFieldNames) => {
    const hasValidationError =
      !!touchedFields[fieldName] && errors[fieldName] ? errors[fieldName] : undefined;
    const hasExternalError =
      externalErrors && externalErrors[fieldName] ? externalErrors[fieldName] : undefined;

    return hasValidationError || hasExternalError;
  };

const createResetExternalErrorsHelper =
  <FormFieldNames extends string>(resetFunction?: (fieldName: FormFieldNames) => void) =>
  (fieldName: FormFieldNames) =>
  () => {
    resetFunction?.(fieldName);
  };

export const createFormikHelpers = <
  FormFieldNames extends string,
  FormValuesInterface extends Record<FormFieldNames, string | number | null | boolean>,
>(
  setFieldValueFunction: FormikHelpers<FormValuesInterface>['setFieldValue'],
  setTouchedFunction: FormikHelpers<FormValuesInterface>['setTouched'],
  touchedFields: FormikTouched<FormValuesInterface>,
  errors: FormikErrors<FormValuesInterface>,
  externalErrors?: Partial<Record<FormFieldNames, string>>,
  resetExternalErrors?: (fieldName: FormFieldNames) => void,
) => {
  const handleInputChange = createInputChangeHelper<FormFieldNames, FormValuesInterface>(
    setFieldValueFunction,
  );
  const handleTouched = createInputTouchedHelper<FormFieldNames, FormValuesInterface>(
    setTouchedFunction,
    touchedFields,
  );
  const getInputErrorMessage = createInputErrorHelper<FormFieldNames, FormValuesInterface>(
    touchedFields,
    errors,
    externalErrors,
  );
  const handleResetExternalError =
    createResetExternalErrorsHelper<FormFieldNames>(resetExternalErrors);

  const hasExternalError = Object.values(externalErrors || {}).some(Boolean);

  return {
    handleInputChange,
    handleTouched,
    getInputErrorMessage,
    handleResetExternalError,
    hasExternalError,
  };
};
